import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";


const Art = () => {
    return (
        <Fragment>
            <Header />
            <div className="pageheader-section style-2" style={{backgroundImage:`url(assets/images/course/Art-Craft-Head.png)`}}>
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7"></div>
                        <div className="col-lg-5 col-8">
                            <div className="pageheader-thumb">
                                <img src="assets/images/course/Art.png" alt="rajibraj91" className="w-100" />
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>ART</h3>
                                            <p>Art for young kids in school is an excellent way to foster creativity, self-expression, and fine motor skills. Here are some art activities and techniques that are suitable for young kids in a school setting:</p>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Drawing and Coloring:</strong> Provide kids with various drawing materials, such as crayons, colored pencils, and markers. Encourage them to draw freely, express their imagination, and explore different colors and shapes. You can also provide coloring sheets or templates for them to fill in with their favorite colors. 
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Painting:</strong> Introduce kids to the joy of painting by using watercolors, tempera paints, or finger paints. Let them experiment with different brushes, sponges, or even their fingers to create unique artworks. Encourage them to paint freely or guide them with simple themes or subjects.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Collage:</strong> Collage art allows kids to create artwork by pasting and arranging different materials like paper, magazine cutouts, fabric, or natural objects. Provide them with a variety of materials and let them explore their creativity by making collages based on themes or their own ideas.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Clay Modeling: </strong> Give kids the opportunity to work with clay or playdough to sculpt and mold various shapes and forms. Clay modeling helps develop fine motor skills and allows kids to express their creativity in a tactile and three-dimensional medium.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Printmaking:</strong> Introduce simple printmaking techniques like stamping or potato printing. Kids can create their own stamps using sponges, fruits, or vegetables, and then use them to make prints on paper or fabric. It's a fun and hands-on activity that allows for experimentation and exploration.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Mixed Media: </strong> Encourage kids to combine different art materials and techniques to create mixed media artwork. They can use a combination of drawing, painting, collage, and more to express their ideas and create visually engaging pieces.
                                                </li>
                                                
                                            </ul>
                                            <p>
                                            Remember to provide a supportive and encouraging environment where kids can freely express themselves through art. Display their artwork in the classroom or organize small exhibitions to celebrate their creativity. Art is a wonderful tool for young kids to explore their imagination, develop their skills, and gain confidence in their abilities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Art;