


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3402.3659429797194!2d74.30706287608315!3d31.486623748807727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903c04b6520d5%3A0x2bca6f2ceaa0afa4!2sHidaya%20International%20School%20Head%20Office!5e0!3m2!1sen!2s!4v1691484000082!5m2!1sen!2s" ></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap;