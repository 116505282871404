import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";


const LanguageCertification = () => {
    return (
        <Fragment>
            <Header />
            <div className="pageheader-section style-2" style={{backgroundImage:`url(assets/images/course/language-Head.png)`}}>
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7"></div>
                        <div className="col-lg-5 col-8">
                            <div className="pageheader-thumb">
                                <img src="assets/images/course/Language-Certifications.png" alt="rajibraj91" className="w-100" />
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>LANGUAGE CERTIFICATION</h3>
                                            <p>Indeed, language certification at an early age in schools can open various avenues for students in their future lives. Here are some ways language certification can benefit students:</p>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Enhanced Communication Skills:</strong> Learning a new language and obtaining a certification at an early age helps students develop strong communication skills. It expands their vocabulary, improves their grammar, and enhances their ability to express themselves effectively. These skills are valuable in various personal and professional contexts.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Cultural Understanding: </strong> Language certification exposes students to different cultures and promotes intercultural competence. By learning a language, students gain insights into the customs, traditions, and perspectives of other cultures. These understanding fosters empathy, tolerance, and appreciation for diversity.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Academic Advantages:</strong> Proficiency in multiple languages can positively impact academic performance. Research suggests that learning a second language improves cognitive abilities, such as problem-solving, critical thinking, and memory. It can also boost academic achievement in other subjects like reading, writing, and math.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Career Opportunities:</strong> Language skills are highly sought after in today's globalized world. Having a language certification sets students apart and broadens their career prospects. It opens doors to various international job opportunities, enhances employability, and increases the chances of working in diverse and multicultural environments.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Travel and Exploration:</strong> Language certification enables students to navigate and communicate effectively while traveling to different countries. It enhances their travel experiences, facilitates interactions with locals, and allows them to immerse themselves in different cultures.
                                                </li>

                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Personal Growth and Adaptability:</strong> Learning a language at an early age develops resilience, adaptability, and a growth mindset in students. It challenges them to step out of their comfort zone, embrace new experiences, and overcome obstacles. These qualities are invaluable in all aspects of life.    
                                                </li>
                                                
                                            </ul>
                                            <p>Language certification programs in schools provide structured learning opportunities and formal recognition of students' language proficiency. They motivate students to achieve specific language goals and validate their language skills, boosting their confidence and self-esteem.</p>
                                            <p>By obtaining language certifications early on, students are well-prepared to explore various avenues in their future lives, including higher education, international careers, cultural exchange programs, and personal enrichment. It equips them with a valuable skill set that extends beyond linguistic competence, empowering them to connect with the world and thrive in a globalized society.</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default LanguageCertification;