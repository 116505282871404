import React,{Fragment,useEffect ,useState} from 'react'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { FacebookProvider, Like } from 'react-facebook';

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import QRCode from "qrcode.react";


const EShopDetail = () =>{
    const downloadQR = () => {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "123456.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    
    return(
        <Fragment>
          <Header />
            <div>
            <FacebookProvider appId="123456789">
                <Like href="https://www.facebook.com" colorScheme="dark" showFaces share />
            </FacebookProvider>
            </div>
            <div className="pageheader-section style-2 mb-20">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                   
                        <div className='col-sm-12'>
                            <table className='table bordered' style={{fontSize:16,fontWeight:800}} >
                                <thead>
                                    <th style={{textAlign:'center',fontSize:29,padding:15,color:'green'}} colSpan={2}>SCHOOL UNIFORM</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowSpan={7}>Reception to KG-2</td>
                                        <td>Blue Trouser Boys/Girls</td>
                                    </tr>
                                    <tr>
                                        <td>Red T-Shirt Polo (Full Sleeves)</td>
                                    </tr>
                                    <tr>
                                        <td>Blue Tunic for Girls</td>
                                    </tr>
                                    <tr>
                                        <td>Black Socks Boys/Girls</td>
                                    </tr>
                                    <tr>
                                        <td>White/Black Sneakers </td>
                                    </tr>
                                    <tr>
                                        <td>Red Zipper (winter) Boys/Girls</td>
                                    </tr>
                                    <tr>
                                        <td>Blue Fleece Trouser (winter) Boys/Girls</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={9}>Year 3 – A -Levels</td>
                                        <td>Blues Trouser (Pent) Boys</td>
                                    </tr>
                                    <tr>
                                        <td>Blue Trouser (straight Pen/Shalwar) Girls</td>
                                    </tr>
                                    <tr>
                                        <td>Red Shirt (Dress) Boys</td>
                                    </tr>
                                    <tr>
                                        <td>Blue Frak Full Sleeves Girls</td>
                                    </tr>
                                    <tr>
                                        <td>Black Socks</td>
                                    </tr>
                                    <tr>
                                        <td>Black Shoes Boys/Girls</td>
                                    </tr>
                                    <tr>
                                        <td>Red Zipper (Winter) Boys/Girls</td>
                                    </tr>
                                    <tr>
                                        <td>Head Scarf (Blue) Girls</td>
                                    </tr>
                                    <tr>
                                        <td>Stole/Dupatta (Blue) Girls</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={3}>Reception to A- Levels</td>
                                        <td>Sports Uniform Boys/Girls</td>
                                    </tr>
                                    <tr>
                                        <td>Zipper/Trouser (Blue)</td>
                                    </tr>
                                    <tr>
                                        <td>T-Shirt Round Neck (Red) </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style={{marginBottom:150}}>
                                <strong>Note:</strong>
                                Available at our website, purchase online, COD facility available.
                            </p>

                            {/* <div>
                            <QRCode
                                id="123456"
                                value='https://drive.google.com/file/d/1Fg9M9NY7q4cAOTf14GRPw__leSZCQXuy/view?usp=sharing'
                                size={290}
                                level={"H"}
                                includeMargin={true}
                            />

                            <a onClick={downloadQR}> Download QR </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
          <Footer />
        </Fragment>
    )
}

export default EShopDetail