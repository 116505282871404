import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";


const MindGames = () => {
    return (
        <Fragment>
            <Header />
            <div className="pageheader-section style-2" style={{backgroundImage:`url(assets/images/course/Mind-Games-Head.png)`}}>
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7"></div>
                        <div className="col-lg-5 col-8">
                            <div className="pageheader-thumb">
                                <img src="assets/images/course/Mind-Games.png" alt="rajibraj91" className="w-100" />
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>Mind Games</h3>
                                            <p>Mind games play a crucial role in the development of students during their pre-schooling years. They help enhance cognitive abilities, problem-solving skills, creativity, and social interaction. Here are some essential and important mind games for Early Yearsstudents:</p>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Memory Games:  </strong>
                                                     Memory games involve remembering and matching pairs of cards or objects. They help improve concentration, focus, and memory skills. Examples include matching cards with identical pictures or remembering a sequence of objects.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Puzzle Games: </strong>
                                                     Puzzles come in various forms, such as jigsaw puzzles, shape puzzles, and tangrams. These games promote spatial awareness, critical thinking, and logical reasoning. Early Yearsstudents can start with simple puzzles and gradually progress to more complex ones.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Sorting and Categorizing Games:</strong> Sorting and categorizing games involve organizing objects based on specific attributes, such as color, shape, size, or type. These games develop cognitive skills, visual discrimination, and problem-solving abilities.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Pattern Recognition Games: </strong>
                                                     Pattern recognition games help Early Yearsstudents identify and continue patterns. They can practice recognizing patterns in colors, shapes, numbers, or objects. These games enhance logical thinking, sequencing skills, and pattern recognition abilities.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Creative Drawing and Art Games:</strong> Creative drawing and art games encourage imagination and self-expression. Students can engage in activities like drawing, coloring, or making collages. These games stimulate creativity, fine motor skills, and visual-spatial awareness.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Language and Word Games: </strong>
                                                     Language and word games introduce Early Yearsstudents to letters, words, and basic language skills. Activities like word puzzles, rhyming games, or storytelling games help develop vocabulary, language fluency, and communication skills.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Social and Cooperative Games: </strong>
                                                     Social and cooperative games involve interaction and collaboration with peers. These games teach early yearsstudents about sharing, taking turns, teamwork, and problem-solving in a social context. Examples include board games, role-playing games, and cooperative building activities.
                                                </li>
                                                
                                            </ul>
                                            <p>It's important to choose mind games that are age-appropriate, engaging, and enjoyable for early yearsstudents. These games should provide an appropriate level of challenge, fostering a positive learning experience. Remember to offer guidance, support, and encouragement while allowing students to explore, discover, and develop their skills through these mind games.</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default MindGames;