import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";


const Stem = () => {
    return (
        <Fragment>
            <Header />
            <div className="pageheader-section style-2" style={{backgroundImage:`url(assets/images/course/STEM-Head-1.png)`}}>
                <div className="container" >
                    <div  className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7 col-4">
                   
                        </div>
                        <div className="col-lg-5 col-8" >
                            <div className="pageheader-thumb">
                                <img src="assets/images/course/STEM.png" alt="rajibraj91" className="w-100" />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>STEM</h3>
                                            <p>STEM (Science, Technology, Engineering, and Mathematics) courses for young kids provide an excellent opportunity to introduce them to the fascinating world of science and technology while nurturing their curiosity and problem-solving skills. Here are some popular STEM courses specifically designed for young kids:</p>
                                            <p>Robotics: Robotics courses teach kids the basics of building and programming robots. They learn about mechanical structures, sensors, motors, and coding, allowing them to design and control their own robots.</p>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Coding and Programming: </strong>
                                                     Coding courses introduce kids to computer programming through fun and interactive activities. They learn the fundamentals of coding languages and create their own games, animations, and interactive stories.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Science Experiments: </strong>
                                                     Science courses for kids focus on hands-on experiments and scientific concepts. They learn about topics such as chemistry, physics, biology, and environmental science through engaging experiments and demonstrations.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Engineering and Design:</strong> Engineering courses encourage kids to think like engineers and solve real-world problems. They learn about engineering principles, design processes, and construct various projects using materials like LEGO or other building kits.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Math Enrichment: </strong>
                                                     Math courses provide a solid foundation in mathematical concepts and problem-solving skills. Kids engage in fun activities, puzzles, and games that help develop their numerical fluency, logical reasoning, and critical thinking abilities.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>STEM Exploration:</strong> These courses offer a broad overview of multiple STEM disciplines. Kids get exposed to various topics like robotics, coding, electronics, and more, allowing them to explore different areas and develop a well-rounded understanding of STEM subjects.
                                                </li>
                                                
                                            </ul>
                                            <p>When selecting STEM courses for young kids, it's essential to consider their age, interests, and skill levels. Look for courses that provide age-appropriate content, hands-on activities, and a supportive learning environment.</p>
                                            <p>Remember, the primary goal of these courses is to inspire a love for learning and exploration in the STEM fields, fostering creativity, problem-solving skills, and a strong foundation for future academic and career paths.</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Stem;