// Flipbook.js
import React, { useState,useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './Flipbook.css';

// Setting up the worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Flipbook = ({ pdfFile }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState('');

  const [showPre ,setShowPre] = useState(false)
  console.log('showPre',showPre)
  const scaleFactor = 1.25; // Example scale factor
  const originalHeight = 0; // Example original height

  useEffect(() => {
    // Calculate and round the height
    const roundedHeight = Math.round(scaleFactor * originalHeight);
    // Set the CSS variable
    document.documentElement.style.setProperty('--rounded-height', `${roundedHeight}px`);
  }, [scaleFactor, originalHeight]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setError('');
  };

  const onDocumentLoadError = (err) => {
    console.error('Failed to load PDF file:', err);
    setError('Failed to load PDF file.');
  };

  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      setShowPre(true)
    }else{
      setShowPre(false)
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      setShowPre(true)
    }
  };

  return (
    <div className="flipbook">
      <div className="controls">
       <p style={{color:'#C08E2D'}}>
          Page {pageNumber} of {numPages}
        </p>
        </div>
      {error ? (
        <div className="error-message" style={{textAlign:'center'}}>{error}</div>
      ) : (
        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          <Page pageNumber={pageNumber} renderTextLayer={false}/>
        </Document>
      )}
      <div className="controls">
        {showPre === true ?
        <button onClick={goToPrevPage} className="nav-button left" disabled={pageNumber <= 1}>
          <i class="icofont-ui-previous"></i> Previous
        </button>
        : null}
       
        <button onClick={goToNextPage} className="nav-button right" disabled={pageNumber >= numPages}>
          Next 
          <i class="icofont-ui-next"></i>
        </button>
      </div>
    </div>
  );
};

export default Flipbook;
