import { Component, Fragment ,useEffect,useRef , useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GroupSelect from "../component/sidebar/group-select";
import Pagination from "../component/sidebar/pagination";
import Rating from "../component/sidebar/rating";
import SkillSelect from "../component/sidebar/skill-select";
import Table from 'react-bootstrap/Table'
import JoditEditor from 'jodit-react';
import axios from "axios";



const AddJobVacancy = () => {
    const job = useRef(null);
    
    const [jobDescription, setJobDescription] = useState('');
    const [responsibilities,setResponsibilities] = useState('')
    const [requirements,setRequirements] = useState('')
    const [qualifications,setQualifications] = useState('')
    

    // address state

    const [country, setCountry] = useState([])
    const [countryID , setCountryID] = useState('')

    const [province , setProvince] = useState([])
    const [provinceID, setProvinceID] = useState('')

    const [city , setCity] = useState([])


    const HandleCountry = async(e) =>{
        const getCountryId = e.target.value
        setCountryID(getCountryId)
    }
    
    const HandleProvince = async(e) =>{
        const getProvinceID = e.target.value
        setProvinceID(getProvinceID)
    }

    useEffect(()=>{
        const getCountry = async() =>{
            const res = await axios.get('http://hidaya-001-site1.etempurl.com/api/address/getcountries')
            const data = await res.data
            setCountry(data.data)
        }
        getCountry()

        const getProvince = async() =>{
            const res = await axios.get(`http://hidaya-001-site1.etempurl.com/api/address/getprovincebycountry?countryid=${countryID}`)
            const data = res.data
            setProvince(data.data)
        }
        getProvince()

        const getCities = async(e) =>{
            const res = await axios.get(`http://hidaya-001-site1.etempurl.com/api/address/getcitybyprovince?provinceId=${provinceID}`)
            const data = res.data
            setCity(data.data)
        } 
        getCities()

    },[countryID,provinceID])

    console.log(responsibilities)
    return (
        <Fragment>
            <Header />
                <div className="pageheader-section style-2 mb-10">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                            <h3>Add Job Vacancy</h3>
                            <form className="form mb-5">
                                <div className="form-group">
                                    <label><h6> <span className="text-danger">*</span> Job Title </h6></label>
                                    <input type="text" className="form-control" placeholder="Enter Job Title" />
                                </div>
                                <div className="row mt-4">
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label><h6>Country</h6></label>
                                            <select name="country" onChange={HandleCountry} className="form-control">
                                                <option>-select country-</option>
                                                {country?.map((item)=>(
                                                    <>
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    </>
                                                ))}
                                                
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label><h6>State</h6></label>
                                            <select name="province" onChange={HandleProvince} className="form-control">
                                                <option>-select state-</option>
                                                {province?.map((pitems)=>(
                                                    <>
                                                        <option key={pitems.id} value={pitems.id}>{pitems.name}</option>
                                                    </>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label><h6>City</h6></label>
                                            <select name="city" className="form-control">
                                                <option>-select city</option>
                                                {city?.map((item) =>(
                                                    <>
                                                        <option key={item.id} value={item.id}> {item.name}</option>
                                                    </>
                                                ))}
                                                    
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-4">
                                    <label><h6><span className="text-danger">*</span> Job Description</h6></label>
                                    <JoditEditor 
                                        ref={job}
                                        value={jobDescription}
                                        onChange={setJobDescription}
                                    />    
                                </div>

                                <div className="form-group mt-4">
                                    <label><h6><span className="text-danger">*</span> Responsibilities:</h6></label>
                                    <JoditEditor 
                                        ref={job}
                                        value={responsibilities}
                                        onChange={setResponsibilities}
                                    />    
                                </div>

                                <div className="form-group mt-4">
                                    <label><h6><span className="text-danger">*</span> Requirements:</h6></label>
                                    <JoditEditor 
                                        ref={job}
                                        value={requirements}
                                        onChange={setRequirements}
                                    />    
                                </div>

                                <div className="form-group mt-4">
                                    <label><h6><span className="text-danger">*</span> Qualifications</h6></label>
                                    <JoditEditor 
                                        ref={job}
                                        value={qualifications}
                                        onChange={setQualifications}
                                    />    
                                </div>
                                

                                <div className="form-group mt-4">
                                    <h5><button className="btn btn-success">Post Vacancy </button></h5>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            <Footer/>
        </Fragment>
    )
}

export default AddJobVacancy