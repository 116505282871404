

const subTitle = "HIDAYA INTERNATIONAL SCHOOLS";
const title = "21 Characteristics";


const featureList = [
    {
        iconName: 'icofont-world',
        title: 'Standards-Based',
        desc: 'International Standards Based Curiculum',
    },
    {
        iconName: 'icofont-handshake-deal',
        title: 'Committed to',
        desc: 'Morality, Ideology, Values & Character Development',
    },
    {
        iconName: 'icofont-holding-hands',
        title: 'Holistic Approach',
        desc: 'with Inclusive Prespective environment as the third teacher',
    },
    {
        iconName: 'icofont-island-alt',
        title: 'Arabic Language',
        desc: 'Spoken English Environment & Arabic Language as Second Language with Language Lab',
    },
    {
        iconName: 'icofont-book',
        title: 'Tajveed & Qirat',
        desc: 'Nazra-e-Quran (Compulsory) with Tajveed & Qirat and Hifz-e-Quran (Optional)',
    },
    {
        iconName: 'icofont-quill-pen',
        title: "Iqbal `Shanasi`",
        desc: 'Fahm-e-Quran, Seerat-un-Nabi, Iqbal `Shanasi`',
    },
    {
        iconName: 'icofont-brand-walmart',
        title: 'Skill Set',
        desc: '21st Century Skill Set',
    },
    {
        iconName: 'icofont-compass-alt',
        title: 'Aptitude Assessment',
        desc: 'Personalized Learning through Aptitude Assessment, Map Testing and Profiling',
    },
    {
        iconName: 'icofont-mathematical',
        title: 'Combining Instructional Methodologies',
        desc: 'Play-Way Learning, Inquiry-Based Learning, Experiential Learning, Blended Learning, Project-Based Learning, Discovery-Based Learning',
    },
    {
        iconName: 'icofont-artichoke',
        title: 'Applied Sense',
        desc: 'Connecting with Art, Literature, History and Geography in Applied Sense',
    },
    {
        iconName: 'icofont-help-robot',
        title: 'Robotics Learning',
        desc: 'STEM, Artificial Intelligence, Coding and Robotics Learning',
    },
    {
        iconName: 'icofont-library',
        title: 'Curricular, Co-Curricular & Extra-Curricular',
        desc: 'Curricular, Co-Curricular & Extra-Curricular Activities, Clubs, Societies, Magazines, Resource Rooms/Library',
    },
    {
        iconName: 'icofont-basketball-hoop',
        title: 'Motor and Cognitive Development',
        desc: 'Motor and Cognitive Development Athletic Programs, Sports, Swimming and Martial Arts',
    },
    {
        iconName: 'icofont-airplane-alt',
        title: 'Outdoor Education',
        desc: 'Outdoor Education National and Intenational Tours, Student Exchange Programs, Summer Study Abroad',
    },
    {
        iconName: 'icofont-users-social',
        title: 'Student Social Responsibility - SSR',
        desc: 'Student Social Responsibility - SSR Community Learning and Services',
    },
    {
        iconName: 'icofont-fast-food',
        title: 'Self-Care & Awareness',
        desc: 'Self-Care & Awareness Medical, Phychological and Nutritional',
    },
    {
        iconName: 'icofont-bin',
        title: 'Lifestyle Coaching',
        desc: 'Well-being, Behavioral and Lifestyle Coaching',
    },
    {
        iconName: 'icofont-brand-myspace',
        title: 'Parents`',
        desc: 'Parents` Forums, Counseling Sessions and Workshops',
    },
    {
        iconName: 'icofont-smart-phone',
        title: 'Mobile App',
        desc: 'Learning Management System & Mobile App',
    },
    {
        iconName: 'icofont-racing-flag',
        title: 'Capacity Building & Professional Development',
        desc: 'Regular and Focused Capacity Building & Professional Development for Academic and Administrative Staff',
    },
    {
        iconName: 'icofont-brand-tribenet',
        title: 'Global Educational Networks',
        desc: 'Partnerships, Collaborations & HR Exchange with Global Educational Networks, Forums & Institutions',
    }
]


const FeatureTwo = () => {
    return (
        <section className="feature-section style-2 padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle yellow-color">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 justify-content-center">
                        {featureList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="feature-item">
                                    <div className="feature-inner">
                                        <div className="feature-icon">
                                            <i className={val.iconName}></i>
                                        </div>
                                        <div className="feature-content">
                                            <h5>{val.title}</h5>
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default FeatureTwo;