import React,{Fragment,useEffect ,useState} from 'react'

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import QRCode from "qrcode.react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page } from 'react-pdf';


const Prospectus = () =>{
    // const [numPages, setNumPages] = useState<number>();
    // const [pageNumber, setPageNumber] = useState<number>(1);
    // function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    //     setNumPages(numPages);
    // }
    // const onDocumentLoadSuccess = () =>{

    // }

    const [numPages, setNumPages] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages: number }) => {
        setNumPages(numPages);
    }
    
    return(
        <Fragment>
          <Header />
          <div className="pageheader-section style-2 mb-10">
            <div className="shop-cart padding-tb">
                <div className="container">
                <HTMLFlipBook
                    width={550}
                    height={650}
                    minWidth={315}
                    maxWidth={1000}
                    minHeight={420}
                    maxHeight={1350}
                    showCover={true}
                    
                    flippingTime={1000}
                    style={{ margin: "0 auto" }}
                    maxShadowOpacity={0.5}
                   // className="album-web"
                >
                        
                        <div className="demoPage">
                            <img src="/assets/images/book/page1.jpg" height={500} alt="page1"/>
                        </div>
                        <div className="demoPage">
                            <img src="/assets/images/book/page2.jpg" height={500} alt="page1"/>
                        </div>
                        <div className="demoPage">
                            <img src="/assets/images/book/page3.jpg" height={500} alt="page1"/>
                        </div>
                        <div className="demoPage">
                            <img src="/assets/images/book/page4.jpg" height={500} alt="page1"/>
                        </div>
                    </HTMLFlipBook>
                </div>
            </div>
        </div>
            
        <Footer />
        </Fragment>
    )
}

export default Prospectus