import { Component, Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import CartContext from "../context/cart-context";
import AddProductsForm from "../component/sidebar/add-products-form";

const AddProduct = () => {
  const { cartSubTotal, cartItems } = useContext(CartContext);

  const [formData, setFormData] = useState({});

  // Callback function to receive form data and update state
  const handleFormSubmit = (data) => {
    setFormData(data);
    console.log("data on check out:", data);
  };

  const handleConfirmOrder = () => {
    const combinedData = {
      formData,
      cartSubTotal,
      cartItems,
    };
    console.log("Order Details:", combinedData);
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Add Product"} curPage={"Add Product Page"} />
      <div className="shop-cart padding-tb">
        <div className="container">
          <div className="section-wrapper">
            <div className="cart-bottom">
              <div className="shiping-box">
                <div className="row justify-content-center">
                  <div className="col-md-7 col-12">
                    <div className="calculate-shiping">
                      <h3>Add Item</h3>
                      <AddProductsForm onSubmit={handleFormSubmit} />
                    </div>
                  </div>
                  {/* <div className="col-md-5 col-12">
                    <div className="cart-overview">
                      <h3>Cart Totals</h3>
                      <ul className="lab-ul">
                        <li>
                          <span className="pull-left">Cart Subtotal</span>
                          <p className="pull-right">
                            {"$" + cartSubTotal + ".00"}
                          </p>
                        </li>
                        <li>
                          <span className="pull-left">
                            Shipping and Handling
                          </span>
                          <p className="pull-right">Free Shipping</p>
                        </li>
                        <hr className="divider-line my-5" />

                        <li>
                          <h5 className="pull-left">Total amount</h5>
                          <h3 className="pull-right">$999.00</h3>
                        </li>
                        <button
                          className="lab-btn w-100 mt-3"
                          onClick={handleConfirmOrder}
                        >
                          <span>Confirm Order</span>
                        </button>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default AddProduct;
