import React,{Fragment} from 'react'

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import axios from 'axios';
import { Link } from 'react-router-dom';


const CodeofConduct = () =>{
    return(
        <Fragment>
            <Header />
                <div className="pageheader-section style-2 mb-20">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse"></div>
                            <div className='col-sm-12 mb-10'>
                                <h2><u><center>CODE OF CONDUCTS</center></u></h2> 
                                    <p>Hidaya international Schools aim to promote positive behaviour, respect for others, and a safe and inclusive learning environment.</p>     
                                    <ol>
                                        <li>
                                            <strong>RESPECT FOR SELF AND OTHERS:</strong>
                                            This includes treating everyone with dignity and respect, regardless of differences in race, ethnicity, culture, gender, religion, and other personal characteristics.
                                        </li>
                                        <li>
                                            <strong>RESPONSIBILITY AND ACCOUNTABILITY: </strong>
                                            Students should be expected to take responsibility for their actions and be held accountable for any behaviour that violates the code of conduct.
                                        </li>
                                        <li>
                                            <strong>ZERO TOLERANCE FOR BULLYING AND HARASSMENT: </strong>
                                            The school should have a clear policy on bullying and harassment and should take swift action to address any incidents that occur.
                                        </li>
                                        <li>
                                            <strong>SAFETY AND SECURITY: </strong>
                                            The school should have measures in place to ensure the safety and security of students, staff, and visitors.
                                        </li>
                                        <li>
                                            <strong>ACADEMIC HONESTY AND INTEGRITY: </strong>
                                            Students should be expected to maintain academic honesty and integrity and avoid cheating or plagiarism in all forms.
                                        </li>
                                        <li>
                                            <strong>
                                            APPROPRIATE USE OF TECHNOLOGY: 
                                            </strong>
                                            Students should be expected to use technology responsibly and in accordance with the school's policies.
                                        </li>
                                        <li>
                                            <strong>RESPECT FOR SCHOOL PROPERTY</strong>
                                            Students should be expected to take care of school property and not engage in behaviour that could cause damage or harm.
                                        </li>
                                        <li>
                                            <strong>SUBSTANCE ABUSE PREVENTION: </strong>
                                            The school should have a clear policy on substance abuse prevention and provide support and resources for students who need help.
                                        </li>
                                        <li>
                                            <strong>INCLUSIVITY AND DIVERSITY: </strong>
                                            The school should promote inclusivity and diversity and strive to create a learning environment that is welcoming and inclusive for all students.
                                        </li>
                                        <li>
                                            <strong>KINDNESS AND RESPECT: </strong>
                                            Younger students should be encouraged to treat others with kindness and respect, using polite language and avoiding actions that could hurt others' feelings.
                                        </li>
                                        <li>
                                            <strong>RESPONSIBILITY: </strong>
                                            Younger students should be taught to take responsibility for their actions, such as cleaning up after themselves, following rules, and being accountable for their behaviour.
                                        </li>

                                        <li>
                                            <strong>SAFETY: </strong>
                                            Younger students should be taught the importance of staying safe, such as not running in the halls, looking both ways before crossing the street, and reporting any safety concerns to a teacher or adult.
                                        </li>
                                        <li>
                                            <strong>HONESTY: </strong>
                                            Younger students should be taught the importance of telling the truth, avoiding cheating, and being fair in all their dealings with others.
                                        </li>
                                        <li>
                                            <strong>SHARING AND COOPERATION: </strong>
                                            Younger students should be encouraged to share, take turns, and work together, helping to create a positive and inclusive learning environment.
                                        </li>
                                        <li>
                                            <strong>CARING FOR THE ENVIRONMENT: </strong>
                                            Younger students should be taught the importance of taking care of the environment and preserving the planet for future generations.
                                        </li>
                                        <li>
                                            <strong>LISTENING: </strong>
                                            Younger students should be encouraged to listen to others, follow instructions, and respect the opinions and feelings of others.
                                        </li>
                                        <li>
                                            <strong>POSITIVE ATTITUDES: </strong>
                                            Younger students should be encouraged to have a positive attitude, to be supportive of others, and to celebrate the successes of others.
                                        </li>
                                    </ol>
                                    <h3><u>CODE OF CONDUCT FOR PARENTS</u></h3>
                                    <p>
                                        A code of conduct for parents should aim to promote positive relationships between parents, teachers, and school staff, and to ensure a safe and inclusive learning environment for all students. Here are some key elements that could be included in a code of conduct for parents:
                                    </p>
                                    <ol>
                                        <li>
                                            <strong>RESPECT FOR THE SCHOOL AND ITS STAFF: </strong>
                                            : Parents should be expected to show respect for the school and its staff, including teachers, administrators, and support staff.
                                        </li>
                                        <li>
                                            <strong>COMMUNICATION: </strong>
                                            Parents should be encouraged to communicate regularly with the school and to provide regular updates on their child's progress and any concerns they may have.
                                        </li>
                                        <li>
                                            <strong>SUPPORT FOR SCHOOL POLICIES:</strong>
                                            Parents should be expected to support the school's policies and to reinforce the importance of following rules and regulations at home.
                                        </li>
                                        <li>
                                            <strong>RESPONSIBILITY FOR STUDENT BEHAVIOUR: </strong>
                                            Parents should be responsible for their child's behaviour both at school and at home,  and should work with the school to address any behaviour issues that arise.
                                        </li>
                                        <li>
                                            <strong>COLLABORATION: </strong>
                                            Parents should be encouraged to collaborate with the school and to participate in school events, volunteering opportunities, and other activities.
                                        </li>
                                        <li>
                                            <strong>NCLUSIVITY AND DIVERSITY: </strong>
                                            Parents should support the school's efforts to promote inclusivity and diversity,  and should strive to create a positive and inclusive learning environment for all students.
                                        </li>
                                        <li>
                                            <strong>CONFIDENTIALITY: </strong>
                                            Parents should respect the confidentiality of other families and not share information about other students or families with others.
                                        </li>
                                        <li>
                                            <strong>POSITIVE ATTITUDES: </strong>
                                            Parents should be encouraged to maintain a positive attitude and to support the school and its staff in their efforts to provide a high-quality education for all students.
                                        </li>
                                    </ol>
                                    <h3><u>CODE OF CONDUCT FOR TEACHERS</u></h3>
                                    <p>A code of conduct for teachers should aim to promote a safe, inclusive, and effective learning environment for all students, and to support the goals and values of the school. Here are some key elements that could be included in a code of conduct for teachers:</p>
                                    <ol>
                                        <li>
                                            <strong>PROFESSIONALISM: </strong>
                                            Teachers should be expected to maintain a high level of professionalism, including dressing appropriately, arriving on time, and being prepared for class.
                                        </li>
                                        <li>
                                            <strong>RESPECT FOR STUDENTS:</strong>
                                            Teachers should be expected to treat all students with dignity and respect, regardless of differences in race, ethnicity, culture, gender, religion, and other personal characteristics.
                                        </li>
                                        <li>
                                            <strong>FAIR AND EFFECTIVE TEACHING PRACTICES: </strong>
                                            Teachers should be expected to use fair and effective teaching practices, including creating a positive and inclusive learning environment, using evidence-based instructional strategies, and regularly assessing student progress.
                                        </li>
                                        <li>
                                            <strong>SAFETY AND SECURITY: </strong>
                                            Teachers should be responsible for ensuring the safety and security of their students and should report any safety concerns to the appropriate authorities.
                                        </li>
                                        <li>
                                            <strong>CONFIDENTIALITY: </strong>
                                            Teachers should respect the confidentiality of students and their families and should not share personal information with others without proper authorization.
                                        </li>
                                        <li>
                                            <strong>INCLUSIVITY AND DIVERSITY: </strong>
                                            Teachers should strive to promote inclusivity and diversity in their classrooms and to create a learning environment that is welcoming and inclusive for all students.
                                        </li>
                                        <li>
                                            <strong>COMMUNICATION: </strong>
                                            Teachers should be responsive and proactive in their communication with students, their families, and other teachers and school staff.
                                        </li>
                                        <li>
                                            <strong>CONTINUOUS PROFESSIONAL GROWTH: </strong>
                                            Teachers should be committed to continuous professional growth and should regularly engage in professional development opportunities to improve their skills and knowledge.
                                        </li>
                                    </ol>
                            </div>
                        </div>
                    </div>
            <Footer/>
        </Fragment>
    )
}
export default CodeofConduct