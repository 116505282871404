import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import baseURL from '../api/index';
import { useEffect } from "react";

const title = "Login";
const socialTitle = "Login With Social Media";
const btnText = "Sign in";


const OnlineTestForm = () => {
    const navigate = useNavigate()
    const [username , setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [usererror,setUserError] = useState('')
    
    const handleUsername = e =>{
        e.preventDefault()
        const value = e.target.value
        setUsername(value)
    }
    
    const handlePassword = e =>{
        e.preventDefault()
        const value = e.target.value
        setPassword(value)
    }
    const handleChange = (e) =>{
        e.preventDefault()
        setValue(e.target.value)
    } 

    const [value, setValue] = useState('')

    const onPaste = (e) => {
        e.preventDefault()
    }


    const handleLogin = async(e) =>{
        try {

            e.preventDefault()
            const fillData = {
                username:username,
                password:password
            }
            if(username === '' || password === ''){
                setUserError('Please Enter Username/Password')
            }else{
                const data = await axios.post(`${baseURL}/api/authentication/login`,fillData)
                console.log('login data ',data.data)
                
                localStorage.setItem('isUserLogin',JSON.stringify(data))
                const localStorageData = localStorage.getItem('isUserLogin')
                navigate('/careers')
                console.log('data ',localStorageData)
                setUserError('')
            }
        } catch (error) {
            setUserError('Username/Password is not valid')   
        }
    }

    const [bgcolor ,setBGColor] = useState('red')
    const [color , setColor] = useState('white')
    const [isset ,setIsset] = useState(true)
    useEffect(()=>{
        const ColorChange = () =>{
            const timer = setTimeout(() => {
                if(isset === true){
                    setBGColor('yellow')
                    setColor('red')
                    setIsset(false) 
                }else{
                    setBGColor('red')
                    setColor('white')
                    setIsset(true)  
                }
                
            }, 500);
            return () => clearTimeout(timer);
        }
        ColorChange()
    },[isset])

    return (
        <Fragment>
            <Header />
                <div className="pageheader-section style-2 mb-20">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center ">         
                            {/* <div className="col-md-1">
                            </div> */}
                            <div className="col-md-9" style={{marginBottom:100}}>
                                <form >
                                    <div  className="row">
                                        <div className="col-12">
                                            <p>Short Answers: These questions assess a candidate's knowledge,
                                                skills, and experience in teaching primary school students. They cover various
                                                areas such as classroom management, instructional strategies, assessment and
                                                feedback, parent communication, collaboration, student engagement,
                                                differentiated instruction, and professional development.
                                            </p>
                                            <div className="form-group" style={{marginTop:14}}>
                                                <label style={{marginBottom:4}}><strong>1: </strong>How would you create a positive and inclusive classroom environment for primary school students?</label>
                                                <textarea
                                                    value={value}
                                                    type="text"
                                                    name="name"
                                                    placeholder="Answer"
                                                    onChange={handleChange}
                                                    onPaste={onPaste}
                                                    onCut={onPaste}
                                                    onCopy={onPaste}
                                                />
                                            </div>
                                            <div className="form-group" style={{marginTop:14}}>
                                                <button className="btn btn-success" ><b>Next</b></button>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                                        
                            </div>
                            
                        </div>
                    </div>
                </div>
            
            <Footer />
        </Fragment>
    );
}
 
export default OnlineTestForm;