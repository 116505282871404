import { Fragment ,useEffect,useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import baseURL from '../api/index';
import ClipLoader from "react-spinners/ClipLoader";
import toast from 'react-hot-toast' 
import OtpInput from 'react18-input-otp';
import { Card } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import "../assets/css/styledVerify.css";
import Loader from '../loader/Loader'
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';


const title = "Register Now";
const socialTitle = "Register With Social Media";
const btnText = "Regsiter";




const SignupPage = () => {
    const [basicModal, setBasicModal] = useState(false);
    const [loader , setLoader] = useState(false)
    const navigate = useNavigate()
    const [password, setPassword] =useState('')
    const [confirmpassword, setConfirmPassword] = useState('')
    const [email , setEmail] = useState('')
    const [emailerr,setEmailErr] = useState(false)
    const [confirmError ,setConfirmError] = useState(false)
    const [mainError,setMainError] = useState('')
    const [username , setUserName] = useState('')
    const [enableOTP,setEnableOTP] = useState(false)
    const [mainDataError,setMainDataError]= useState('')
    const [userId,setUserId] = useState('')
    const handleUsername = (e) =>{
        e.preventDefault()
        const value = e.target.value.replace(/\s/g, '')
        setUserName(value)
    }

    useEffect(() =>{
        const handleMachine = () =>{
            let headers = new Headers();
            headers.append('Access-Control-Allow-Origin','http://localhost:3001')
            headers.append('Access-Control-Allow-Headers','Content-Type');
            headers.append('Access-Control-Allow-Headers', 'Authorization');

            const body ={
                username:'superadmin',
                password:'Super12#'
            }
            axios.post(`http://192.168.1.138:8081/api/jwt-api-token-auth/` ,{
                headers: headers,
                body   
            })
            .then(response => {
                // success
                console.log('response',response)
                
                return response.text
            })
            .catch((error) => {
                console.log(error)
            })
        }
        handleMachine()
    },[mainDataError])

    const handleEmail = (e) =>{
        e.preventDefault()
        const value = e.target.value
        setEmail(value)

        console.log(value)
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(reg.test(value)){
            
            setEmailErr(false)
            console.log('value')
        }else{
            setEmailErr(true)
        }
    }
    const [passLength ,setPassLength] = useState('')
    const minLength = 5;
    const handlePassword = (e) =>{
        e.preventDefault()
        const value = e.target.value 
        setPassword(value)

        if(value.length < minLength){
            setPassLength(`Password must be at least ${minLength} characters long.`);
        }else{
            setPassLength('')
        }

        if(confirmpassword === '' || confirmpassword === null){
            
            setConfirmError(false)
        }else{
            if(value !== confirmpassword){
                setConfirmError(true)
            }else{
                setConfirmError(false)
            }
        } 
    }
    const handleConfirmPassword = (e) =>{
        e.preventDefault()
        const value = e.target.value
        setConfirmPassword(value)

        if(value === password){
            setConfirmError(false)
            
        }else{
            setConfirmError(true)
        }
    }

    const handleRegister = async(e) =>{
        e.preventDefault()
        // try {
            if(email !== '' && password !== '' && username!=='' && confirmpassword!==''){
                if(emailerr === false && confirmError === false){
                    const body = {
                        email:email,
                        password:password,
                        username:username
                    }
                    setLoader(true)
                    console.log('body:',body)
                    await axios.post(`https://api.hintschool.org/api/authentication/register`,body)
                    .then(res =>{
                        // if(res.data.status === 200){
                        //     // setUserId(res.data?.data?.data)
                        setLoader(false)
                        setBasicModal(true)
                        setEnableOTP(true)
                        console.log('res',res)
                        setMainError('')
                        // }else{
                        //     setMainError('')
                        // }
                    })
                    .catch(error =>{
                        console.log('res:',error.message)
                        // setMainError(''+err.response.message)
                        if (error.response) {
                            setLoader(false)
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log('Error status:', error.response.status);
                            console.log('Error headers:', error.response.headers);
                            console.log('Error data:', error.response.data.message);
                            if( error.response.status === 500){
                                setMainError('Your enter valid email address')
                            }else{
                                setMainError(''+error.response.data.message)
                            }
                            
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log('Error request:', error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error message:', error.message);
                        }
                        console.log('Error config:', error.config);
                    })
                    // console.log('post data' , data.status)
                    
                    
                }else{
                    setMainError('Please Enter Valid Data')
                }
            }else{
                setMainError('Please fill all information')
            }
        // } catch (error) {
        //     setMainError('')
        // }
    }

    const handleBack =(e) =>{
        e.preventDefault()
        setEnableOTP(false)
    }
    const handleOTP = async(e) =>{
        e.preventDefault()
        await axios.post(`${baseURL}/api/authentication/validatecode?userId=${userId}&code=${otp}`)
        .then((response) =>{
            if(response.status === 200){ 
                setBasicModal(true)
                // navigate('/login')
            }
        })
        .catch((error) =>{
            setMainDataError('Please Enter Correct Code')
        })
    }


    useEffect(() => {
        if(basicModal){
            const timer = setTimeout(() => {
                navigate('/login')
            }, 7000);
            return () => clearTimeout(timer);
        }
        fetchData()
      }, [basicModal,handleRegister]);

    // otp code
    const [otp, setOtp] = useState('');
    const handleChange = (enteredOtp) => {
       setOtp(enteredOtp);
    };

    const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [showModal, setShowModal] = useState(false);

    const fetchData = async () => {
		try {
		  // Simulating an API call
		  const response = await new Promise((resolve) =>
			setTimeout(() => resolve({ data: 'Some data' }), 2000)
		  );
		  setData(response.data);
		  setLoading(false); // Data is fetched, stop loading
		} catch (error) {
		  console.error('Error fetching data:', error);
		  setLoading(false); // Stop loading even if there's an error
		}
	  };
	
	  if (loading) {
		return <Loader />; // Show loader while loading
	  }
    return (
        <Fragment>
            <Header />
                {enableOTP === false ?
                <div className="pageheader-section style-2 mb-20">
                    <div className="container">
                        {loader ? 
                        <div className="row">
                            <center>
                                <MDBModal show={true} tabIndex='-1'>
                                    <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                                        <ClipLoader
                                            marginTop={300}
                                            color={'#fff'}
                                            loading={loader}
                                            size={70}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </div>
                                </MDBModal>
                            </center>
                        </div>
                        : null }
                        
                            
                            <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">         
                                <div className="login-section mb-20">
                                    <div className="account-wrapper">
                                            <h3 className="title">{title}</h3>
                                            <form className="account-form">
                                                {mainError ? 
                                                    <h5 className="text-danger">{mainError}</h5>
                                                :null}
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        value={username}
                                                        onChange={handleUsername}
                                                        name="name"
                                                        placeholder="Enter username without space"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={email}
                                                        placeholder="Email"
                                                        onChange={handleEmail}
                                                    />
                                                    {emailerr ? 
                                                        <p className="text-danger">Please Enter Valid Email Address</p>
                                                    : null}
                                                </div>
                                                {passLength ?
                                                    <b className="text-danger">
                                                            * {passLength}
                                                    </b>
                                                    : null
                                                }
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        placeholder="Password"
                                                        value={password}
                                                        onChange={handlePassword}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        value={confirmpassword}
                                                        onChange={handleConfirmPassword}
                                                        placeholder="Confirm Password"
                                                    />
                                                    {confirmError ? 
                                                        <p className="text-danger">
                                                            Confirm password doesn't match
                                                        </p>
                                                    :null}
                                                </div>
                                                <div className="form-group">
                                                    <button className="lab-btn" disabled={passLength || confirmError ? true : false} onClick={handleRegister}><span>{btnText}</span></button>
                                                </div>
                                            </form>
                                            <div className="account-bottom">
                                                <span className="d-block cate pt-10">Are you a member? <Link className="btn btn-danger" style={{fontWeight:600}} to="/login">Login</Link></span>
                                                
                                            </div>
                                        </div>
                                
                                </div>
                            </div>
                        
                        
                </div>
                </div>

                :
                <>
                    <div className="pageheader-section style-2 mb-20">
                    <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="login-section mb-20" style={{marginBottom:50}}>
                            <div className="account-wrapper">
                                <p>Enter 6 digits OTP Code sent to your email </p>
                                <form className="account-form">

                            
                                    <OtpInput 
                                        className='marginTop form-control'
                                        id="myInput"
                                        value={otp} 
                                        placeholder="------"
                                        onChange={handleChange} 
                                        numInputs={6} 
                                        isSuccessed={false}
                                        errorStyle="error"
                                        successStyle="success"
                                        separator={<span></span>} 
                                        shouldAutoFocus
                                        onSubmit={console.log(otp)}
                                    />
                               
                                    <div className="form-group">
                                        <center className="text-danger">{mainDataError}</center>
                                    </div>
                               
                                <div className="form-group">
                                    <button className="lab-btn" onClick={handleOTP}><span>SUBMIT</span></button>
                                </div>
                                <div>
                                    <button className="btn btn-link" onClick={handleBack}>
                                        <i class="icofont-long-arrow-left"></i> Back
                                    </button>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    <div className="row">
                        <center>
                        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                            <MDBModalDialog>
                            <MDBModalContent>
                                
                                <MDBModalHeader>
                                    <MDBModalTitle>
                                        <center>
                                        
                                        <p style={{fontWeight:600,fontSize:23}}>Congratulations! Your email address has been </p>
                                        <h3 className='text-success'>
                                          successfully verified <i class="icofont-verification-check"></i>
                                        </h3>
                                        </center>
                                    </MDBModalTitle>
                                </MDBModalHeader>
                            </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>
                        </center>
                    </div>
                </>
                }
            <Footer />
        </Fragment>
    );
}
 
export default SignupPage;