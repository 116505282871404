
import {
	createBrowserRouter, 
	Route, 
	createRoutesFromElements,
	RouterProvider} from "react-router-dom";
import 'swiper/css';
import ScrollToTop from "./component/layout/ScrollToTop";
import AdmissionForm from './page/AdmissionForm'
import InqueryForm from './page/InqueryForm'
import AdmissionProcedure from "./page/AdmissionProcedure";
import HomeTwo from "./page/home-2";
import LoginPage from "./page/login";
import SignupPage from "./page/signup";
import TeamPage from "./page/team";
import Accreditations from "./page/Accreditations";
import Academics from "./page/Academics";
import AssociateLetter from "./page/AssociateLetter";
import Coding from "./page/Coding";
import Stem from "./page/Stem";
import Art from "./page/Art";
import LanguageCertification from './page/LanguageCertification'
import MindGames from './page/MindGames'
import GraphicDesinging from './page/GraphicDesgning'



import AboutUs from './page/AboutUS';
import CodeOfConduct from './page/CodeOfConduct';
import Contact from './page/contact';

import AddJobVacancy from './page/AddJobVacancy';
import Protected from "./page/Protected";
import Logout from "./page/Logout";
import ClubSocities from './page/ClubSocieties';
import ClubsAndSocities from "./page/ClubsAndSocities";

import { CartContextProvider } from "./context/cart-context";
import Blog from './page/blog'
import SingleBlog from './page/blog-single'

import OnlineTestForm from './page/OnlineTestForm'
import EShopDetail from './page/eshop_detail'

import AddProduct from "./page/add-product";
import CheckOutPage from "./page/check-out-page";
import CartPage from "./page/cart-page";
import PDFFile from './page/pdffile'
import Prospectus from './page/prospectus'
import OTP from './page/otp'
// import CampusTeam from './page/CampusTeam'
import Loader from './loader/Loader';



import AppoinmentPage from "./page/appoinmnet";
import React, { Suspense, useEffect, useState } from "react";
// import CampusFacultyDetail from './page/campus-faculty-detail'
// import ShopPage from "./page/shop";
//import ShopDetails from "./page/shop-single";
// import Careers from './page/Careers'
// import JobDescription from './page/JobDescription';
// import CareersForm from './page/CareersForm';

const Careers = React.lazy(() => import('./page/Careers'))
const JobDescription = React.lazy(() => import('./page/JobDescription'))
const CareersForm = React.lazy(() => import('./page/CareersForm'))
const ShopPage = React.lazy(() => import("./page/shop"))
const ShopDetails = React.lazy(() => import('./page/shop-single'))
const CampusFacultyDetail = React.lazy(() => import('./page/campus-faculty-detail'))
const CampusTeam = React.lazy(() => import('./page/CampusTeam'))
const CampusList = React.lazy(() => import('./page/campus-list')) 
const AssociateModel = React.lazy(() => import('./component/associate-model'))
const Modal = React.lazy(() => import("./component/model"))


const router = createBrowserRouter(
	createRoutesFromElements(
		
			<Route>
				<Route index element={<HomeTwo />} />
				
				<Route path="/blogs" element={<Blog />} />
				<Route path="singleblog" element={<SingleBlog />} />
				<Route path="/inqueryform" element={<InqueryForm />} />
				<Route path="/appointment" element={<AppoinmentPage />} />
				<Route path="/admissionprocedure" element={<AdmissionProcedure/>} />
				<Route path="/hintsteam" element={<TeamPage/>} />

								
					<Route path="/campuses-network" element={<CampusList />} />	
					<Route path="/campus-faculty/:id" element={<CampusTeam/>} />
					<Route path="/campus-faculty-member-detail/:id" element={<CampusFacultyDetail/>} />
				

				<Route path="/coding" element={<Coding/>} />
				<Route path="/stem" element={<Stem/>} />
				<Route path="/art" element={<Art/>} />
				<Route path="/languagecertification" element={<LanguageCertification/>} />
				<Route path="/mindgames" element={<MindGames/>} />
				<Route path="/graphicdesinging" element={<GraphicDesinging/>} />

				<Route path='/accreditations' element={<Accreditations/>} />
				<Route path='/academics' element={<Academics/>} />
				<Route path='/associateLetter'  element={<AssociateLetter/>}/>
				<Route path="/associatemodel" element={<AssociateModel />} />

				<Route path='/careers' element={<Protected Component={Careers} />} />
				<Route path='/jobdescription/:id' element={<Protected Component={JobDescription} />} />
				<Route path='/applyforjob/:id' element={<Protected Component={CareersForm} /> } />
				<Route path='/login' element={<LoginPage/>} />
				<Route path="/signup" element={<SignupPage/>} />

				<Route path="/addjobvacancy" element={<AddJobVacancy/>} />
				<Route path="/logout" element={<Logout/>} />
				<Route path="/about-us" element={<AboutUs/>} />
				<Route path="/code-of-conducts" element={<CodeOfConduct/>} />
				<Route path='/club-socities' element={<ClubSocities/>} />
				<Route path="/clubs-and-socities/:id" element={<ClubsAndSocities/>} />
			
				<Route path="/contact-us" element={<Contact/>} />
				<Route path="/online-test-form"  element={<OnlineTestForm/>}/>
				<Route path="/uniform" element={<EShopDetail/>} />

				<Route path="/eshop" element={<ShopPage />} />
				<Route path="shop-single/:productID" element={<ShopDetails />} />
				<Route path="add-product" element={<AddProduct />} />
				<Route path="cart-page" element={<CartPage />} />
          		<Route path="check-out-page" element={<CheckOutPage />} />
				<Route path="PDFFile" element={<PDFFile/>} />
				<Route path="/prospectus" element={<Prospectus/>} />
				<Route path="/otp/:id" element={<OTP/>} />
			</Route>
		
	)
)

function App() {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		// Simulate data fetching
		fetchData();
		setShowModal(true);
	  }, []);
	
	const handleClose = () => {
		setShowModal(false);
	};

	  const fetchData = async () => {
		try {
		  // Simulating an API call
		  const response = await new Promise((resolve) =>
			setTimeout(() => resolve({ data: 'Some data' }), 2000)
		  );
		  setData(response.data);
		  setLoading(false); // Data is fetched, stop loading
		} catch (error) {
		  console.error('Error fetching data:', error);
		  setLoading(false); // Stop loading even if there's an error
		}
	  };
	
	  if (loading) {
		return <Loader />; // Show loader while loading
	  }

	return (
		<>
			{/* <ScrollToTop /> */}
			<CartContextProvider >
			<Suspense>
			<RouterProvider router={router} />
			
			</Suspense>
			</CartContextProvider>
		</>
	);
}

export default App;
