import { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Rating from "../component/sidebar/rating";

const instructorList = [
    {
        imgUrl: 'assets/images/team/yabseenkhan.png',
        imgAlt: 'Yabseen Khan',
        name: 'Yabseen Khan',
        degi: 'Chairman'
    },
    {
        imgUrl: 'assets/images/team/ali_raza.png',
        imgAlt: 'Ali Raza',
        name: 'Ali Raza',
        degi: 'Chief Executive Officer (CEO)'
    },
    {
        imgUrl: 'assets/images/team/Tauseef-ur-Rehman.png',
        imgAlt: 'Tauseef-ur-Rehman',
        name: 'Tauseef-ur-Rehman',
        degi: 'Director Administration'
    },
    {
        imgUrl: 'assets/images/team/imtiaz-ahmad.png',
        imgAlt: 'imtiaz-ahmad',
        name: 'Imtiaz Ahmad',
        degi: 'Senior Vice President'
    },
    {
        imgUrl: 'assets/images/team/Hilal-Ahmad-Wani.png',
        imgAlt: 'Hilal-Ahmad-Wani',
        name: 'Hilal Ahmad Wani',
        degi: 'Senior Vice Chairman'
    },
    {
        imgUrl: 'assets/images/team/Nadeem-Akhtar.png',
        imgAlt: 'Nadeem-Akhtar',
        name: 'Nadeem Akhtar',
        degi: 'Vice Chairman'
    },
    {
        imgUrl: 'assets/images/team/Shahzad-Cheema.png',
        imgAlt: 'Shahzad-Cheema',
        name: 'Shahzad Cheema',
        degi: 'Secretary General'
    },
    {
        imgUrl: 'assets/images/team/Suhail-Bashir.png',
        imgAlt: 'Shahzad-Cheema',
        name: 'Suhail Bashir',
        degi: 'Director Finance'
    },
    {
        imgUrl: 'assets/images/team/Hafiz-Ejaz-Ul-Haq.png',
        imgAlt: 'Hafiz-Ejaz-Ul-Haq',
        name: 'Hafiz Ejaz Ul Haq',
        degi: 'Finance Secretary'
    },
    {
        imgUrl: 'assets/images/team/Imran-Khan.png',
        imgAlt: 'Imran-Khan',
        name: 'Imran Khan',
        degi: 'Joint Secretary'
    }

]




const TeamPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Hidaya International Schools Board of Governors'} curPage={'Board of Governors'} />
            <div className="instructor-section padding-tb section-bg">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
                            {instructorList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="instructor-item">
                                        <div className="instructor-inner">
                                            <div className="instructor-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="instructor-content">
                                               <h4>{val.name}</h4>
                                                <p>{val.degi}</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}
 
export default TeamPage;