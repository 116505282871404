import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";

const Accreditations = () => {
  return (
    <Fragment>
        <Header />
        <div className="pageheader-section style-2 mb-20" style={{marginBottom:100}}>
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse"></div>
                            <div className='col-sm-12 mb-10 mt-20'>
                                <h2>Accreditation</h2>
                                <h6>Oxford International Curriculum (OIC)</h6>
                                <h6>Cambridge, O-Level, A-Levels</h6>
                                <h6>Online Courses with and without certificates</h6>
                                <h6>Google and Microsoft Certifications</h6>
                            </div>
                        </div>
                </div>
        <Footer />
    </Fragment>
  )
}

export default Accreditations;