import React from 'react';
import './Loader.css'; // Assume we have some CSS for the loader

const Loader = () => (
  <div className="loader" style={{backgroundColor:'#1f1f33'}} >
    <img src="/assets/images/logo/hint_golden.png" alt="logo" style={{width:'15%'}} />
    <div className="mt-5 spinner"></div>
    
  </div>
);

export default Loader;