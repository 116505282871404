import React,{Fragment} from 'react'

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import axios from 'axios';
import { Link } from 'react-router-dom';


const AdmissionProcedure = () =>{
    return(
        <Fragment>
            <Header />
                <div className="pageheader-section style-2 mb-40" style={{marginBottom:100}}>
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse"></div>
                            <div className='col-sm-12 mb-10'>
                                <h2>Admission Procedure:</h2> 
                                <p>To apply for admission, please visit our website www.hintschools.com and follow the steps below:</p>     
                                <ol>
                                    <li>Sign in to the admission inquiry form.</li>
                                    <li>Fill in the required forms and upload necessary documents.</li>
                                    <li>You will be assigned a schedule for the admission test, including the date, time, and venue.</li>
                                    <li>After successfully passing the admission test and interview, you will be assigned a username and password to login to the www.portal.hintschool.com website.</li>
                                    <li>Generate the fee voucher and make the payment as guided by the portal. Payment can be made online or downloaded as a voucher and submitted over the bank counter. The paid stamp voucher should then be uploaded to the portal.</li>
                                    <li>Once the payment is processed, you will have full access to the LMS and portal as a parent or student. You can view your timetable, teacher information, and other related information in the portal.</li><li>After admission is complete, you will receive a welcome gift hamper from Hidaya International School through TCS or mail. The hamper will include a welcome letter, uniform, sports uniform and shoes, books and stationery, student card, school bag, water bottle, and a random gift.</li>
                                    <li>On your first day of school, a welcome photo will be taken, and you will be guided to your class teacher and classroom.</li>
                                </ol>
                            </div>
                        </div>
                </div>
            <Footer />
        </Fragment>
        
    )
}

export default AdmissionProcedure