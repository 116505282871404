import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";


const GraphicDesgning = () => {
    return (
        <Fragment>
            <Header />
            <div className="pageheader-section style-2" style={{backgroundImage:`url(assets/images/course/Graphic-Designing-Head.png)`}}>
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7"></div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/course/Graphic-Desinging.png" alt="rajibraj91" className="w-100" />
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>GRAPHIC DESIGNING</h3>
                                            <p>Graphic designing can indeed play a significant role in helping students develop their soft skills and unleash their creativity. Here's how graphic designing can benefit students and potentially lead to early-age freelancing:</p>      
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Visual Communication:</strong> Graphic designing teaches students how to visually communicate ideas and messages effectively. They learn to use design elements such as colors, typography, and images to convey concepts, emotions, and information. This skill enhances their ability to express themselves visually in various contexts.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Critical Thinking and Problem-Solving: </strong> Graphic designing require students to analyze, evaluate, and solve design problems. They learn to think critically about composition, layout, and visual hierarchy to create impactful designs. These problem-solving skills transfer to other areas of life and academic pursuits.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Creativity and Imagination: </strong> Graphic designing provides a platform for students to explore their creativity and imagination. They can bring their ideas to life through design, experimenting with different styles, techniques, and visual approaches. This nurtures their artistic expression and fosters innovation.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Attention to Detail: </strong> Graphic designers must pay attention to details such as alignment, spacing, and consistency. Students develop a keen eye for precision and develop meticulousness in their work. This attention to detail extends to other areas of their academic and personal lives.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>	Collaboration and Communication: </strong> Graphic designing often involves working in teams or collaborating with clients. Students learn to effectively communicate their design ideas, take feedback, and incorporate suggestions. These collaborative experiences improve their interpersonal and communication skills.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Time Management and Organization: </strong> Graphic designing projects have deadlines and require students to manage their time efficiently. They learn to prioritize tasks, meet project milestones, and work within given timelines. This helps them develop strong organizational and time management skills.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Entrepreneurial Mindset: </strong> Graphic designing provides students with the opportunity to explore freelancing at an early age. They can develop a portfolio of their work, showcase their skills online, and offer design services to clients. This early exposure to entrepreneurship nurtures their business acumen and self-reliance.
                                                </li>
                                                
                                            </ul>
                                            <p>It's important for students to receive guidance, mentorship, and constructive feedback as they explore graphic designing. This will help them refine their skills, develop a professional portfolio, and gain confidence in their abilities. Whether pursuing a career in graphic design or utilizing it as a valuable skill set, students can benefit greatly from the creative and entrepreneurial opportunities that graphic designing offers.</p>    
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default GraphicDesgning;