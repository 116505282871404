import React,{Fragment} from 'react'

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

const data = [
    {
        id: 1,
        title : 'Art & Craft Club',
        desc : '<p>An art and craft club is a student-led or school-sponsored club that focuses on creative arts and crafts activities. The goal of an art and craft club is to provide students with a space to explore their creative side, learn new skills, and express themselves through various forms of art and craft.</p><p>Some of the activities that might be offered in an art and craft club include:</p><ul><li><strong>Painting:</strong> Students can experiment with different painting techniques, such as watercolor, acrylic, or oil painting.</li><li><strong>Drawing:</strong> Students can learn how to draw and sketch using pencils, charcoal, or other media.</li><li><strong>Sculpture:</strong> Students can learn how to create three-dimensional objects using materials such as clay, paper make, or found objects.</li><li><strong>Textile arts:</strong> Students can learn how to sew, knit, or crochet and create their own unique textiles.</li><li><strong>Printmaking:</strong> Students can learn how to create prints using techniques such as linocut or etching.</li><li><strong>Paper crafts:</strong> Students can learn how to create a variety of paper crafts, such as origami, paper cutouts, or paper make.</li></ul><p>In an art and craft club, students will have the opportunity to learn from experienced artists and craftspeople and from each other. They also could participate in group projects, exhibitions, and art competitions. Overall, an art and craft club provide students with a supportive and encouraging environment to explore their creative side, learn new skills, and express themselves through various forms of art and craft.</p>'
    },
    {
        id:2,
        title:'Scrabble Club',
        desc:'<p>A Scrabble club is a student-led or school-sponsored club that focuses on playing the popular word game Scrabble. The goal of a Scrabble club is to provide students with a space to learn new words, improve their vocabulary, and challenge themselves through competitive play.</p><p>In a Scrabble club, students will have the opportunity to play Scrabble with their peers and learn strategies and tactics for the game. They also could participate in Scrabble tournaments, where they can test their skills against other players. Scrabble is a great way to improve vocabulary, increase knowledge of word roots and etymology, and develop critical thinking skills. It can also help to improve spelling and improve communication skills, as players must articulate their word choices and explain why they made certain moves. Additionally, playing Scrabble in a club setting provides a fun and social environment where students can connect with others who share their interest in the game. They can also make new friends and build teamwork skills as they work together to come up with high-scoring words. Overall, a Scrabble club provides students with a fun and educational way to improve their language skills, develop their critical thinking abilities, and connect with others who share their interests.</p>',
    },
    {
        id:3,
        title:'Abacus Club',
        desc:'<p>An abacus club is a student-led or school-sponsored club that focuses on learning how to use an abacus for arithmetic calculations. The goal of an abacus club is to provide students with a hands-on way to learn basic mathematical concepts and improve their mental arithmetic skills.</p><p>An abacus is a traditional calculating tool that has been used for centuries to perform arithmetic calculations. It consists of a frame with rods on which beads can be moved to represent numbers.</p><p>In an abacus club, students will learn how to use the abacus to perform arithmetic operations such as addition, subtraction, multiplication, and division. They will also learn mental arithmetic techniques that will help them to perform calculations more quickly and accurately.</p><p>The use of an abacus helps to improve visual-spatial skills and memory retention, as students must visualize the movement of the beads and remember the positions of the beads to perform calculations.</p><p>Additionally, learning to use an abacus provides a fun and engaging way for students to learn basic mathematical concepts and improve their mental arithmetic skills. It can also help to foster a love of learning and a lifelong appreciation for mathematics.</p><p>Overall, an abacus club provides students with a hands-on and fun way to learn basic mathematical concepts and improve their mental arithmetic skills. It can also help to improve visual-spatial skills, memory retention, and foster a love of learning.</p>'
    },
    {
        id:4,
        title: 'Chess Club',
        desc :'<p>A Chess club is a student-led or school-sponsored club that focuses on playing the game of chess. The goal of a Chess club is to provide students with a space to learn the game, improve their strategic thinking skills, and challenge themselves through competitive play.</p><p>In a Chess club, students will have the opportunity to play chess with their peers and learn the rules and strategies of the game. They will also participate in chess tournaments, where they can test their skills against other players.</p><p>Chess is a great way to improve strategic thinking, develop problem-solving skills, and increase creativity. It can also help to improve concentration, as players must focus on the game and anticipate their opponents moves.</p><p>Additionally, playing chess in a club setting provides a fun and social environment where students can connect with others who share their interest in the game. They can also make new friends and build teamwork skills as they work together to improve their game.</p><p>Overall, a Chess club provides students with a fun and educational way to improve their strategic thinking skills, develop problem-solving abilities, and connect with others who share their interests.</p>'
    },
    {
        id:5,
        title:'Story Club',
        desc:'<p>A Story club is a student-led or school-sponsored club that focuses on the love of storytelling and the art of writing. The goal of a Story club is to provide students with the opportunity to explore their imagination, develop their writing skills, and connect with others who share their interests.</p><p>In a Story club, students will have the opportunity to participate in writing workshops and activities, where they can practice their writing skills and receive feedback from their peers. They will also have the opportunity to read and discuss a variety of stories and learn about different storytelling techniques and styles.</p><p>Participating in a Story club helps students to develop their imagination, creativity, and writing skills, as well as their appreciation for the art of storytelling. It also provides a fun and engaging way for students to explore their love of storytelling and to connect with others who share their interests.</p><p>Additionally, a Story club provides a supportive and encouraging environment where students can connect with others who share their interest in storytelling and writing. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Story club provides students with a fun and educational way to explore their love of storytelling, develop their writing skills, and connect with others who share their interests. It can also help to foster a lifelong love of learning and a passion for storytelling and writing.</p>'
    },
    {
        id:6,
        title:'Lago Robotics Club',
        desc:'<p>A Robotics club is a student-led or school-sponsored club that focuses on the design, construction, and programming of robots. The goal of a Robotics club is to provide students with hands-on learning experiences in science, technology, engineering, and mathematics (STEM) fields.</p><p>In a Robotics club, students will have the opportunity to work on projects related to robotics and learn how to design, build, and program robots using a variety of tools and technologies. They will also could participate in robotics competitions, where they can showcase their skills and compete against other teams.</p><p>Participating in a Robotics club helps to develop important STEM skills, including problem-solving, critical thinking, and creativity. It also provides students with hands-on experience in engineering and technology, which can be valuable for future careers in these fields.</p><p>Additionally, a Robotics club provides a fun and engaging environment where students can connect with others who share their interest in robotics and STEM fields. They can also make new friends and build teamwork skills as they work together on projects and competitions.</p><p>Overall, a Robotics club provides students with a hands-on and fun way to learn about STEM fields and develop important skills in problem-solving, critical thinking, and creativity. It can also help to foster a love of learning and a lifelong appreciation for technology and engineering.</p>'
    },
    {
        id:7,
        title:'Calligraphy Club',
        desc:'<p>A Calligraphy club is a student-led or school-sponsored club that focuses on the art of beautiful handwriting. The goal of a Calligraphy club is to provide students with the opportunity to learn about this ancient art form and improve their handwriting skills.</p><p>In a Calligraphy club, students will learn about the history and techniques of calligraphy, including the use of different pens, ink, and paper. They will also have the opportunity to practice and improve their handwriting skills through a variety of calligraphy exercises and projects.</p><p>Learning calligraphy helps to improve fine motor skills, handwriting, and attention to detail. It also provides a creative outlet for students to express themselves and develop their own unique style.</p><p>Additionally, a Calligraphy club provides a fun and relaxing environment where students can connect with others who share their interest in this art form. They can also make new friends and build teamwork skills as they work together on calligraphy projects and exercises.</p><p>Overall, a Calligraphy club provides students with a fun and educational way to learn about this ancient art form and improve their handwriting skills. It can also help to improve fine motor skills, attention to detail, and provide a creative outlet for self-expression.</p>'
    },
    {
        id:8,
        title:'Personality Development Club',
        desc:'<p>A Personality Development club is a student-led or school-sponsored club that focuses on helping students improve their overall personal and interpersonal skills. The goal of a Personality Development club is to provide students with the opportunity to learn about various aspects of personal growth and development and to apply these lessons in their daily lives.</p><p>In a Personality Development club, students will learn about topics such as communication skills, self-confidence, leadership, time management, stress management, and interpersonal relationships. They will also have the opportunity to participate in activities and discussions that help them to apply these lessons in their own lives.</p><p>Participating in a Personality Development club helps students to improve their overall self-awareness, self-esteem, and interpersonal skills. It also provides a supportive and encouraging environment for students to explore their own strengths and weaknesses and develop strategies for personal growth and improvement.</p><p>Additionally, a Personality Development club provides a fun and engaging environment where students can connect with others who share their interest in personal growth and development. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Personality Development club provides students with a fun and educational way to learn about various aspects of personal growth and development and to apply these lessons in their daily lives. It can also help to improve self-awareness, self-esteem, and interpersonal skills, and provide a supportive environment for personal growth and improvement.</p>'
    },
    {
        id:9,
        title:'General Awareness and International Understanding Club',
        desc:'<p>A General Awareness and International Understanding club is a student-led or school-sponsored club that focuses on promoting global awareness and understanding among students. The goal of this club is to provide students with a platform to learn about different cultures, political systems, and global issues and to develop an appreciation for diversity and cultural exchange.</p><p>In this club, students will have the opportunity to learn about current events, international relations, and cultural differences. They may participate in discussions, debates, and presentations on a variety of topics related to global awareness and understanding. They may also have the opportunity to learn about different countries and cultures through guest speakers, field trips, or cultural events.</p><p>Participating in a General Awareness and International Understanding club helps students to broaden their perspectives, develop critical thinking skills, and become more informed citizens. It also promotes cultural exchange and understanding, helping students to appreciate the diversity of the world and develop a sense of global citizenship.</p><p>Additionally, this club provides a fun and engaging environment where students can connect with others who share their interest in global awareness and understanding. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a General Awareness and International Understanding club provides students with a fun and educational way to learn about different cultures, political systems, and global issues and to develop an appreciation for diversity and cultural exchange. It can also help to broaden perspectives, develop critical thinking skills, and foster a sense of global citizenship.</p>'
    },
    {
        id:10,
        title:'Sudoku Club',
        desc:'<p>A Sudoku club is a student-led or school-sponsored club that focuses on playing the popular number puzzle game Sudoku. The goal of a Sudoku club is to provide students with a space to challenge themselves and improve their problem-solving and critical thinking skills.</p><p>In a Sudoku club, students will have the opportunity to play Sudoku and learn about different strategies for solving the puzzles. They will also have the opportunity to participate in Sudoku competitions, where they can test their skills against other players.</p><p>Sudoku is a great way to improve problem-solving skills, increase concentration, and develop critical thinking. It can also help to improve logic and deduction skills, as players must use logic and deduction to determine the correct placement of numbers in the puzzle.</p><p>Additionally, playing Sudoku in a club setting provides a fun and social environment where students can connect with others who share their interest in the game. They can also make new friends and build teamwork skills as they work together to improve their game.</p><p>Overall, a Sudoku club provides students with a fun and educational way to challenge themselves, improve their problem-solving and critical thinking skills, and connect with others who share their interests.</p>'
    },
    {
        id:11,
        title:'Taekwondo Club',
        desc:'<p>A Taekwondo club is a student-led or school-sponsored club that focuses on the Korean martial art of Taekwondo. The goal of a Taekwondo club is to provide students with the opportunity to learn this dynamic and exciting martial art, improve their physical fitness, and develop self-defense skills.</p><p>In a Taekwondo club, students will learn the basic techniques and principles of Taekwondo, including strikes, kicks, blocks, and forms. They will also have the opportunity to participate in sparring and other forms of competition, where they can test their skills and challenge themselves.</p><p>Participating in a Taekwondo club helps to improve physical fitness, coordination, and balance, as well as develop self-defense skills. It also provides a fun and engaging way for students to challenge themselves and learn a new sport.</p><p>Additionally, a Taekwondo club provides a supportive and encouraging environment where students can connect with others who share their interest in this martial art. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Taekwondo club provides students with a fun and educational way to learn about this dynamic and exciting martial art, improve their physical fitness, and develop self-defense skills. It can also help to foster a love of learning and a lifelong appreciation for Taekwondo.</p>'
    },
    {
        id:12,
        title:'Astronomy Club',
        desc:'<p>An Astronomy club is a student-led or school-sponsored club that focuses on the study of astronomy and space science. The goal of an Astronomy club is to provide students with the opportunity to learn about the stars, planets, and other celestial objects, as well as the latest advancements in space science and technology.</p><p>In an Astronomy club, students will have the opportunity to learn about the night sky, the solar system, and the universe beyond. They may also have access to telescopes and other equipment to observe celestial objects and learn about the science behind these observations.</p><p>Participating in an Astronomy club helps students to develop an appreciation for science and technology, as well as improve their critical thinking and problem-solving skills. It also provides a fun and engaging way for students to learn about the universe and explore their curiosity about the cosmos.</p><p>Additionally, an Astronomy club provides a supportive and encouraging environment where students can connect with others who share their interest in astronomy and space science. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, an Astronomy club provides students with a fun and educational way to learn about astronomy and space science, improve their critical thinking and problem-solving skills, and connect with others who share their interests.</p>'
    },
    {
        id:13,
        title:'Magic Club',
        desc:'<p>A Magic club is a student-led or school-sponsored club that focuses on the art of magic and illusion. The goal of a Magic club is to provide students with the opportunity to learn about magic tricks, illusions, and sleight-of-hand techniques, as well as to develop their performance skills.</p><p>In a Magic club, students will have the opportunity to learn about various aspects of magic, including card tricks, coin tricks, mentalism, and stage magic. They will also have the opportunity to practice and perform their magic tricks for their peers, helping to build their confidence and performance skills.</p><p>Participating in a Magic club helps to develop critical thinking, problem-solving, and communication skills, as students must use logic and deduction to figure out how tricks are performed and must communicate their tricks effectively to their audience.</p><p>Additionally, a Magic club provides a fun and engaging environment where students can connect with others who share their interest in magic and illusions. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Magic club provides students with a fun and educational way to learn about magic and illusions, develop critical thinking and problem-solving skills, and connect with others who share their interests.</p>'
    },
    {
        id:14,
        title:'Photography Club',
        desc:'<p>A Photography club is a student-led or school-sponsored club that focuses on the art and science of photography. The goal of a Photography club is to provide students with the opportunity to learn about photography, including camera operation, lighting, composition, and post-processing.</p><p>In a Photography club, students will have the opportunity to learn about various aspects of photography, including camera operation, lighting, composition, and post-processing. They will also have the opportunity to participate in photography projects and activities, where they can apply their knowledge and skills and share their work with others.</p><p>Participating in a Photography club helps students to develop their creativity and technical skills, as well as their appreciation for the art and science of photography. It also provides a fun and engaging way for students to explore their interest in photography and to develop their own unique style.</p><p>Additionally, a Photography club provides a supportive and encouraging environment where students can connect with others who share their interest in photography. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Photography club provides students with a fun and educational way to learn about photography, develop their creativity and technical skills, and connect with others who share their interests.</p>'
    },
    {
        id:15,
        title:'Housekeeping Club',
        desc:'<p>A Housekeeping club is a student-led or school-sponsored club that focuses on teaching students about household management and home organization. The goal of a housekeeping club is to provide students with the skills and knowledge they need to keep their homes clean, organized, and functioning smoothly.</p><p>In a Housekeeping club, students will learn about various aspects of household management, including cleaning, organizing, and maintaining a home. They will also have the opportunity to participate in hands-on projects and activities, where they can apply their knowledge and skills.</p><p>Participating in a Housekeeping club helps students to develop practical skills and knowledge that they can use in their daily lives, as well as to become more self-sufficient and responsible. It also provides a fun and engaging way for students to learn about household management and home organization.</p><p>Additionally, a housekeeping club provides a supportive and encouraging environment where students can connect with others who share their interest in household management and home organization. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Housekeeping club provides students with a fun and educational way to learn about household management and home organization, develop practical skills and knowledge, and connect with others who share their interests.</p>'
    },
    {
        id:16,
        title:'Environmental Club',
        desc:'<p>An Environmental club is a student-led or school-sponsored club that focuses on environmental conservation and sustainability. The goal of an Environmental club is to provide students with the opportunity to learn about environmental issues, conservation, and sustainability, and to take action to protect the environment.</p><p>In an Environmental club, students will learn about various aspects of environmental conservation and sustainability, including climate change, waste reduction, and energy conservation. They will also have the opportunity to participate in hands-on projects and activities, such as recycling programs, tree-planting projects, and community clean-ups, where they can make a positive impact on the environment.</p><p>Participating in an Environmental club helps students to develop an appreciation for the environment, as well as critical thinking and problem-solving skills. It also provides a fun and engaging way for students to learn about environmental conservation and sustainability and to take action to protect the environment.</p><p>Additionally, an Environmental club provides a supportive and encouraging environment where students can connect with others who share their interest in environmental conservation and sustainability. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, an Environmental club provides students with a fun and educational way to learn about environmental conservation and sustainability, take action to protect the environment, and connect with others who share their interests.</p>'
    },
    {
        id:17,
        title:'Literary Club',
        desc:'<p>A Literary club is a student-led or school-sponsored club that focuses on the study of literature and writing. The goal of a Literary club is to provide students with the opportunity to explore their love of literature, improve their writing skills, and connect with others who share their interests.</p><p>In a Literary club, students will have the opportunity to read and discuss a variety of literary works, including novels, poetry, and short stories. They will also have the opportunity to participate in writing workshops, where they can practice their writing skills and receive feedback from their peers.</p><p>Participating in a Literary club helps students to develop their critical reading and writing skills, as well as their appreciation for literature. It also provides a fun and engaging way for students to explore their love of literature and to connect with others who share their interests.</p><p>Additionally, a Literary club provides a supportive and encouraging environment where students can connect with others who share their interest in literature and writing. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Literary club provides students with a fun and educational way to explore their love of literature, improve their writing skills, and connect with others who share their interests. It can also help to foster a lifelong love of learning and a passion for literature and writing.</p>'
    },
    {
        id:18,
        title:'MUN (Model United Nations)',
        desc:'<p>A Model United Nations (MUN) club is a student-led or school-sponsored club that focuses on simulating the workings of the United Nations. The goal of an MUN club is to provide students with the opportunity to learn about international relations, diplomacy, and global issues, and to develop their research, public speaking, and negotiation skills.</p><p>In an MUN club, students will learn about the workings of the United Nations and participate in simulated UN meetings, where they will represent different countries and negotiate on various global issues. They will also have the opportunity to research and write position papers, prepare speeches, and participate in debates and negotiations with their peers.</p><p>Participating in an MUN club helps students to develop their research, public speaking, and negotiation skills, as well as their understanding of international relations and diplomacy. It also provides a fun and engaging way for students to learn about global issues and to connect with others who share their interests.</p><p>Additionally, an MUN club provides a supportive and encouraging environment where students can connect with others who share their interest in international relations and diplomacy. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, an MUN club provides students with a fun and educational way to learn about international relations, diplomacy, and global issues, develop their research, public speaking, and negotiation skills, and connect with others who share their interests.</p>'
    },
    {
        id:19,
        title:'Digital Club',
        desc:'<p>A Digital club is a student-led or school-sponsored club that focuses on digital technology and computer science. The goal of a Digital club is to provide students with the opportunity to learn about various aspects of digital technology, including computer programming, digital design, and emerging technologies.</p><p>In a Digital club, students will have the opportunity to learn about computer programming, digital design, and emerging technologies. They will also have the opportunity to participate in hands-on projects and activities that help them to apply their knowledge and skills.</p><p>Participating in a Digital club helps students to develop technical skills and knowledge, as well as critical thinking and problem-solving skills. It also provides a fun and engaging way for students to explore their interest in digital technology and to prepare for future careers in technology and computer science.</p><p>Additionally, a Digital club provides a supportive and encouraging environment where students can connect with others who share their interest in digital technology. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Digital club provides students with a fun and educational way to learn about digital technology and computer science, develop technical skills and knowledge, and connect with others who share their interests.</p>'
    },
    {
        id:20,
        title:'Legal Literacy and Human Rights Club',
        desc: '<p>A Legal Literacy and Human Rights club is a student-led or school-sponsored club that focuses on the study of law and human rights. The goal of a Legal Literacy and Human Rights club is to provide students with the opportunity to learn about the legal system, human rights, and social justice, and to develop their critical thinking and problem-solving skills.</p><p>In a Legal Literacy and Human Rights club, students will learn about various aspects of the legal system, human rights, and social justice. They will also have the opportunity to participate in discussions, debates, and other activities that help them to understand and analyze complex legal and social issues.</p><p>Participating in a Legal Literacy and Human Rights club helps students to develop their critical thinking and problem-solving skills, as well as their understanding of the legal system and human rights. It also provides a fun and engaging way for students to learn about these important topics and to connect with others who share their interests.</p><p>Additionally, a Legal Literacy and Human Rights club provides a supportive and encouraging environment where students can connect with others who share their interest in law and human rights. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Legal Literacy and Human Rights club provides students with a fun and educational way to learn about the legal system, human rights, and social justice, develop their critical thinking and problem-solving skills, and connect with others who share their interests.</p>'
    },
    {
        id:21,
        title:'Interact and Social Responsibility Club',
        desc:'<p>An Interact and Social Responsibility club is a student-led or school-sponsored club that focuses on community service and social responsibility. The goal of an Interact and Social Responsibility club is to provide students with the opportunity to make a positive impact on their community and to develop their leadership, teamwork, and communication skills.</p><p>In an Interact and Social Responsibility club, students will have the opportunity to participate in community service projects and activities, such as volunteering at local organizations, organizing fundraising events, and participating in environmental conservation projects. They will also have the opportunity to learn about various aspects of social responsibility and to develop their leadership, teamwork, and communication skills.</p><p>Participating in an Interact and Social Responsibility club helps students to develop a sense of social responsibility and civic engagement, as well as leadership, teamwork, and communication skills. It also provides a fun and engaging way for students to make a positive impact on their community and to connect with others who share their interests.</p><p>Additionally, an Interact and Social Responsibility club provides a supportive and encouraging environment where students can connect with others who share their interest in community service and social responsibility. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, an Interact and Social Responsibility club provides students with a fun and educational way to make a positive impact on their community, develop leadership, teamwork, and communication skills, and connect with others who share their interests.</p>'
    },
    {
        id:22,
        title:'Quran Club (mandatory for all students)',
        desc:'<p>A Quran club is a student-led or school-sponsored club that focuses on the study and understanding of the Quran, the holy book of Islam. The goal of a Quran club is to provide students with the opportunity to learn about the teachings and values of the Quran, and to deepen their understanding of their faith.</p><p>In a Quran club, students will have the opportunity to learn about the Quranic teachings, including its history, stories, and moral lessons. They will also have the opportunity to participate in Quranic recitation and memorization, as well as discussions and other activities that help them to understand the Quranic teachings and apply them to their daily lives.</p><p>Participating in a Quran club helps students to deepen their understanding of their faith, as well as to develop their Quranic recitation and memorization skills. It also provides a supportive and encouraging environment where students can connect with others who share their interest in the Quran and to explore their faith in a meaningful way.</p><p>Additionally, a Quran club provides a fun and engaging way for students to learn about the Quranic teachings and to connect with others who share their interests. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Quran club provides students with a fun and educational way to deepen their understanding of the Quran, develop Quranic recitation and memorization skills, and connect with others who share their interests in the Quranic teachings and values.</p>'
    },
    {
        id:23,
        title:'Environmental and sustainability club',
        desc:'<p>Club</p><p>An Environmental and Sustainability club is a student-led or school-sponsored club that focuses on environmental conservation and sustainability. The goal of an Environmental and Sustainability club is to provide students with the opportunity to learn about environmental issues, conservation, and sustainability, and to take action to protect the environment.</p><p>In an Environmental and Sustainability club, students will learn about various aspects of environmental conservation and sustainability, including climate change, waste reduction, energy conservation, and sustainable living. They will also have the opportunity to participate in hands-on projects and activities, such as recycling programs, tree-planting projects, and community clean-ups, where they can make a positive impact on the environment.</p><p>Participating in an Environmental and Sustainability club helps students to develop an appreciation for the environment, as well as critical thinking and problem-solving skills. It also provides a fun and engaging way for students to learn about environmental conservation and sustainability and to take action to protect the environment.</p><p>Additionally, an Environmental and Sustainability club provides a supportive and encouraging environment where students can connect with others who share their interest in environmental conservation and sustainability. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, an Environmental and Sustainability club provides students with a fun and educational way to learn about environmental conservation and sustainability, take action to protect the environment, and connect with others who share their interests.</p>'
    },
    {
        id:24,
        title:'Gaming and Technology Club',
        desc:'<p>A Gaming and Technology club is a student-led or school-sponsored club that focuses on the study of gaming and technology. The goal of a Gaming and Technology club is to provide students with the opportunity to learn about the latest advancements in technology, gaming, and digital media, and to develop their technical skills and creativity.</p><p>In a Gaming and Technology club, students will have the opportunity to learn about various aspects of gaming and technology, including game design, programming, and digital media. They will also have the opportunity to participate in hands-on projects and activities, such as game design challenges and programming competitions, where they can apply their knowledge and skills.</p><p>Participating in a Gaming and Technology club helps students to develop their technical skills, creativity, and problem-solving skills, as well as their understanding of the latest advancements in technology and gaming. It also provides a fun and engaging way for students to explore their interest in gaming and technology and to connect with others who share their interests.</p><p>Additionally, a Gaming and Technology club provides a supportive and encouraging environment where students can connect with others who share their interest in gaming and technology. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Gaming and Technology club provides students with a fun and educational way to learn about gaming and technology, develop their technical skills and creativity, and connect with others who share their interests.</p>'
    },
    {
        id:25,
        title:'Entrepreneurship and Business Club',
        desc:'<p>An Entrepreneurship and Business club is a student-led or school-sponsored club that focuses on entrepreneurship and business. The goal of an Entrepreneurship and Business club is to provide students with the opportunity to learn about entrepreneurship, business, and finance, and to develop their entrepreneurial and business skills.</p><p>In an Entrepreneurship and Business club, students will learn about various aspects of entrepreneurship, business, and finance, including market research, product development, and financial planning. They will also have the opportunity to participate in hands-on projects and activities, such as business plan competitions and entrepreneurial challenges, where they can apply their knowledge and skills.</p><p>Participating in an Entrepreneurship and Business club helps students to develop their entrepreneurial and business skills, as well as their understanding of entrepreneurship and business. It also provides a fun and engaging way for students to explore their interest in entrepreneurship and business and to connect with others who share their interests.</p><p>Additionally, an Entrepreneurship and Business club provides a supportive and encouraging environment where students can connect with others who share their interest in entrepreneurship and business. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, an Entrepreneurship and Business club provides students with a fun and educational way to learn about entrepreneurship and business, develop their entrepreneurial and business skills, and connect with others who share their interests.</p>'
    },
    {
        id:26,
        title:'Health and Wellness Club',
        desc:'<p>A Health and Wellness club is a student-led or school-sponsored club that focuses on health and wellness education. The goal of a Health and Wellness club is to provide students with the opportunity to learn about health and wellness, and to develop healthy habits and lifestyle choices.</p><p>In a Health and Wellness club, students will learn about various aspects of health and wellness, including nutrition, physical activity, stress management, and mental health. They will also have the opportunity to participate in hands-on projects and activities, such as fitness challenges, healthy cooking workshops, and stress management workshops, where they can apply their knowledge and skills.</p><p>Participating in a Health and Wellness club helps students to develop healthy habits and lifestyle choices, as well as their understanding of health and wellness. It also provides a fun and engaging way for students to learn about health and wellness and to connect with others who share their interests.</p><p>Additionally, a Health and Wellness club provides a supportive and encouraging environment where students can connect with others who share their interest in health and wellness. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Health and Wellness club provides students with a fun and educational way to learn about health and wellness, develop healthy habits and lifestyle choices, and connect with others who share their interests.</p>'
    },
    {
        id:27,
        title:'History and Culture Club',
        desc:'<p>A History and Culture club is a student-led or school-sponsored club that focuses on the study of history and culture. The goal of a History and Culture club is to provide students with the opportunity to learn about the history and culture of different regions, civilizations, and time periods, and to develop their critical thinking and research skills.</p><p>In a History and Culture club, students will learn about various aspects of history and culture, including world history, regional history, and cultural traditions. They will also have the opportunity to participate in hands-on projects and activities, such as historical reenactments, cultural celebrations, and historical research projects, where they can apply their knowledge and skills.</p><p>Participating in a History and Culture club helps students to develop their critical thinking and research skills, as well as their understanding of the history and culture of different regions, civilizations, and time periods. It also provides a fun and engaging way for students to learn about history and culture and to connect with others who share their interests.</p><p>Additionally, a History and Culture club provides a supportive and encouraging environment where students can connect with others who share their interest in history and culture. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a History and Culture club provides students with a fun and educational way to learn about history and culture, develop critical thinking and research skills, and connect with others who share their interests.</p>'
    },
    {
        id:28,
        title:'Community Service and Volunteer Club',
        desc:'<p>A Community Service and Volunteer club is a student-led or school-sponsored club that focuses on community service and volunteerism. The goal of a Community Service and Volunteer club is to provide students with the opportunity to make a positive impact on their community and to develop their leadership, teamwork, and communication skills.</p><p>In a Community Service and Volunteer club, students will have the opportunity to participate in community service projects and volunteer activities, such as volunteering at local organizations, organizing fundraising events, and participating in environmental conservation projects. They will also have the opportunity to develop their leadership, teamwork, and communication skills as they work on these projects.</p><p>Participating in a Community Service and Volunteer club helps students to develop a sense of social responsibility and civic engagement, as well as leadership, teamwork, and communication skills. It also provides a fun and engaging way for students to make a positive impact on their community and to connect with others who share their interests.</p><p>Additionally, a Community Service and Volunteer club provides a supportive and encouraging environment where students can connect with others who share their interest in community service and volunteerism. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Community Service and Volunteer club provides students with a fun and educational way to make a positive impact on their community, develop leadership, teamwork, and communication skills, and connect with others who share their interests.</p>'
    },
    {
        id:29,
        title:'Political and Activism Club',
        desc:'<p>A Political and Activism club is a student-led or school-sponsored club that focuses on political awareness and activism. The goal of a Political and Activism club is to provide students with the opportunity to learn about political issues and to develop their advocacy and leadership skills.</p><p>In a Political and Activism club, students will learn about various aspects of politics, including current events, political systems, and social justice issues. They will also have the opportunity to participate in advocacy campaigns and activism projects, such as letter writing campaigns, community organizing events, and protests, where they can apply their knowledge and skills.</p><p>Participating in a Political and Activism club helps students to develop their advocacy and leadership skills, as well as their understanding of political issues and the political process. It also provides a fun and engaging way for students to learn about politics and to connect with others who share their interests.</p><p>Additionally, a Political and Activism club provides a supportive and encouraging environment where students can connect with others who share their interest in political awareness and activism. They can also make new friends and build teamwork skills as they work together on club projects and activities.</p><p>Overall, a Political and Activism club provides students with a fun and educational way to learn about politics, develop advocacy and leadership skills, and connect with others who share their interests.</p>'
    }
]
const ClubsAndSocities = () =>{
    const params = useParams();
    const {id} = params;
    const clubId= id
    console.log('id:',clubId)

    const [getData , setGetData] = useState('')
    
    console.log('id:' ,id)
    
    const found = data.find(({id}) => id == clubId)


    console.log(found);

    return(
        <Fragment>
            <Header />
                <div className="pageheader-section style-2 mb-20">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                            <div className='col-sm-12 mx-10' style={{marginTop:70,marginBottom:120}}>
                                <h3>{found.title}</h3>    
                                <div dangerouslySetInnerHTML={{__html: found.desc}}></div>                                         
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </Fragment>
    )
}

export default ClubsAndSocities