import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import baseURL from '../api/index';
import { useEffect } from "react";
import { Card, CardGroup } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";

const title = "Appointment for Visiting School/Campus";
const socialTitle = "Login With Social Media";
const btnText = "Book Appointment";


const AppoinmentPage = () => {
    const navigate = useNavigate()
    const [username , setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [usererror,setUserError] = useState('')
    
    const handleUsername = e =>{
        e.preventDefault()
        const value = e.target.value
        setUsername(value)
    }
    
    const handlePassword = e =>{
        e.preventDefault()
        const value = e.target.value
        setPassword(value)
    }
    const handleLogin = async(e) =>{
        try {

            e.preventDefault()
            const fillData = {
                username:username,
                password:password
            }
            if(username === '' || password === ''){
                setUserError('Please Enter Username/Password')
            }else{
                const data = await axios.post(`${baseURL}/api/authentication/login`,fillData)
                console.log('login data ',data.data)
                
                localStorage.setItem('isUserLogin',JSON.stringify(data))
                const localStorageData = localStorage.getItem('isUserLogin')
                navigate('/careers')
                console.log('data ',localStorageData)
                setUserError('')
            }
        } catch (error) {
            setUserError('Username/Password is not valid')   
        }
    }

    const [bgcolor ,setBGColor] = useState('red')
    const [color , setColor] = useState('white')
    const [isset ,setIsset] = useState(true)
    
    useEffect(()=>{
        const ColorChange = () =>{
            const timer = setTimeout(() => {
                if(isset === true){
                    setBGColor('yellow')
                    setColor('red')
                    setIsset(false) 
                }else{
                    setBGColor('red')
                    setColor('white')
                    setIsset(true)  
                }
                
            }, 500);
            return () => clearTimeout(timer);
        }
        ColorChange()
    },[isset])

    return (
        <Fragment>
            <Header />
                <div className="pageheader-section style-2 mb-20">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center ">         
                            <div className="col-md-12">
                                <div className="login-section mb-20">
                                    <Card >
                                        
                                        <Card.Body>
                                            <Card.Title>
                                                <h3 className="title mb-5">{title}</h3>
                                            </Card.Title>
                                            
                                            <form className="account-form">
                                                {/* {usererror ? 
                                                    <p className="text-danger">{usererror}</p>
                                                :null} */}
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <strong> Name </strong>
                                                            <input
                                                                value={username}
                                                                type="text"
                                                                name="name"
                                                                placeholder="Contact Person Name"
                                                                onChange={handleUsername}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <strong> Contact No </strong>
                                                            <input
                                                                type="text"
                                                                name="password"
                                                                value={password}
                                                                placeholder="Contact Details"
                                                                onChange={handlePassword}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <strong> Purpose of visit: </strong>
                                                            <select name="" id="">
                                                                <option value={''}>-- select option --</option>
                                                                <option value={1}>Admission Inquiry</option>
                                                                <option value={2}>Meeting/Discussion</option>
                                                                <option value={3}>Other</option>
                                                            </select>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                                <div className="form-group text-center">
                                                    <button onClick={handleLogin} className="d-block lab-btn">  <span> <i class="icofont-ui-calendar"></i> {btnText}</span></button>
                                                </div>
                                            </form>
                                        </Card.Body>
                                            
                                    </Card>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            
            <Footer />
        </Fragment>
    );
}
 
export default AppoinmentPage;