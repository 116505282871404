import OtpInput from 'react18-input-otp';
import React,{useState, Fragment ,useEffect } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import "../assets/css/styledVerify.css";
import { Card } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import axios from 'axios';
import baseURL from '../api';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

const OTP =() => {
  const [otp, setOtp] = useState('');
  const {id} = useParams()
  const handleChange = (enteredOtp) => {
     setOtp(enteredOtp);
  };
  const navigate = useNavigate()
    const [basicModal, setBasicModal] = useState(false);
    const [userId,setUserId] = useState('')
    const [mainDataError,setMainDataError]= useState('')
    
    const handleOTP = async(e) =>{
      e.preventDefault()
      await axios.post(`${baseURL}/api/authentication/validatecode?userId=${id}&code=${otp}`)
      .then((response) =>{
          if(response.status === 200){ 
              setBasicModal(true)

              const timer = setTimeout(() => {
                navigate('/login')
                }, 3000);
                return () => clearTimeout(timer);
     
          }
      })
      .catch((error) =>{
          setMainDataError('Please Enter Correct Code')
      })
  }
  const handleBack =(e) =>{
      e.preventDefault()
  
      navigate('/login')
  }
   return (
     <Fragment>
             <Header />
               <div className="pageheader-section style-2 mb-20">
                    <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="login-section mb-20" style={{marginBottom:50}}>
                            <div className="account-wrapper">
                                <p>Enter 6 digits OTP Code sent to your email </p>
                                <form className="account-form">

                            
                                    <OtpInput 
                                        className='marginTop form-control'
                                        id="myInput"
                                        value={otp} 
                                        placeholder="------"
                                        onChange={handleChange} 
                                        numInputs={6} 
                                        isSuccessed={false}
                                        errorStyle="error"
                                        successStyle="success"
                                        separator={<span></span>} 
                                        shouldAutoFocus
                                        onSubmit={console.log(otp)}
                                    />
                               
                                    <div className="form-group">
                                        <center className="text-danger">{mainDataError}</center>
                                    </div>
                               
                                <div className="form-group">
                                    <button className="lab-btn" onClick={handleOTP}><span>SUBMIT</span></button>
                                </div>
                                <div>
                                    <button className="btn btn-link" onClick={handleBack}>
                                        <i class="icofont-long-arrow-left"></i> Back
                                    </button>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    <div className="row">
                        <center>
                        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                            <MDBModalDialog>
                            <MDBModalContent>
                                
                                <MDBModalHeader>
                                    <MDBModalTitle>
                                        <center>
                                        
                                        <p style={{fontWeight:600,fontSize:23}}>Congratulations! Your email address has been </p>
                                        <h3 className='text-success'>
                                          successfully verified <i class="icofont-verification-check"></i>
                                        </h3>
                                        </center>
                                    </MDBModalTitle>
                                </MDBModalHeader>
                            </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>
                        </center>
                    </div>
         <Footer />
     </Fragment>
   );
}

export default OTP

