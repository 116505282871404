import React,{useEffect} from "react";
import {useNavigate} from 'react-router-dom';

const Protected = (props) =>{
    const {Component} = props;
    const navigate = useNavigate()
    useEffect(()=>{
        let Login = localStorage.getItem('isUserLogin')
        console.log('Login form ',Login)
            
        if(!Login && Login===null){
            navigate('/login')
        }
    },[])

    return(
        <>
            <Component />
        </>
    )
}

export default Protected