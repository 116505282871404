import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";

const AboutUS = () => {
  return (
    <Fragment>
        <Header />
            <div className="pageheader-section style-2 mb-20">
                <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse"></div>
                    <h2>About US</h2>
                    <p>
                      Hidaya International Schools is a prominent educational institution with a global presence, dedicated to providing high-quality education and fostering a nurturing learning environment for students. Our schools are known for their commitment to academic excellence, character development, and holistic growth of every individual.
                    </p>
                    <p>
                      At Hidaya International Schools, we believe in preparing students to become future leaders, critical thinkers, and compassionate individuals who contribute positively to society. Our educational approach combines a rigorous academic curriculum with a strong emphasis on character education, promoting values such as integrity, respect, empathy, and responsibility.
                    </p>
                    <p>
                      Established with a vision to empower students with knowledge and skills that enable them to excel in an ever-changing world, Hidaya International Schools offer a comprehensive education from preschool to high school. Our curriculum is designed to meet international standards and incorporates innovative teaching methods, technology integration, and experiential learning opportunities.
                    </p>
                    <p>
                      We are proud of our highly qualified and dedicated faculty who are passionate about education and committed to nurturing each student's potential. Our teachers provide personalized attention and support, creating a conducive learning environment where students can thrive academically and personally.
                    </p>
                    <p>
                      In addition to academic excellence, Hidaya International Schools also offer a wide range of extracurricular activities, including sports, arts, and community service programs. We believe in the holistic development of our students and encourage them to explore their talents and interests beyond the classroom.
                    </p>
                    <p>
                      With a global outlook, Hidaya International Schools welcome students from diverse backgrounds and cultures, promoting an inclusive and multicultural learning environment. We believe in fostering global citizenship and preparing students to be responsible global leaders who can contribute positively to a rapidly changing world.
                    </p>
                    <p>
                      Whether you are a parent looking for an exceptional educational institution for your child or a student seeking an enriching learning experience, Hidaya International Schools provide a nurturing and academically rigorous environment where students can flourish and achieve their full potential.
                    </p>
                    <p>
                    Come and join us at Hidaya International Schools, where we are committed to shaping the future leaders of tomorrow through excellence in education.
                    </p>


                </div>
            </div>
        
        <Footer />
    </Fragment>
  )
}

export default AboutUS
