import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginPage from './login'

const Logout = () => {
  const navigate = useNavigate()

  useEffect(() =>{
    let logout = localStorage.clear('isUserLogin')
    if(logout){
        navigate('/login')
    }
  },[])

    return (
    <LoginPage/>
    )
}

export default Logout