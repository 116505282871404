import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import Form from "react-bootstrap/Form";

const title = "Item details";
const btnText = "Add Item";

const AddProductsForm = ({ onSubmit }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedGrade, setSelectedGrade] = useState("All");
  const [selectedSizes, setSelectedSizes] = useState([]); // Use an array for selected sizes

  const labelStyle = {
    fontWeight: "bold",
    marginBottom: "5px",
  };

  const [formData, setFormData] = useState({
    itemTitle: "",
    itemPrice: "",
    itemQuantity: "",
    itemDiscount: "",
    itemDescription: "",

    itemGrade: "All",
    itemCategory: "All",
    itemSize: [],
    itemImages: [], // Initialize an array to store multiple images
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);
    setFormData({
      ...formData,
      itemCategory: newCategory,
    });
  };

  const handleGradeChange = (event) => {
    const newGrade = event.target.value;
    setSelectedGrade(newGrade);
    setFormData({
      ...formData,
      itemGrade: newGrade,
    });
  };

  const handleSizeChange = (event) => {
    const newSize = event.target.value;
    setSelectedSizes(
      Array.from(event.target.selectedOptions, (option) => option.value)
    ); // Get an array of selected sizes
    setFormData({
      ...formData,
      itemSize: Array.from(
        event.target.selectedOptions,
        (option) => option.value
      ), // Update the itemSize array with selected sizes
    });
  };

  const handleImageChange = (event) => {
    // Handle multiple image URLs as comma-separated strings
    const imageUrls = event.target.value.split(",");
    setFormData({
      ...formData,
      itemImages: imageUrls,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);

    const baseUrl = process.env.REACT_APP_HINT_PRODUCTS_URL;
    const endpoint = "/add";
    const url = baseUrl + endpoint;

    const requestData = {
      gradeId: parseInt(formData.itemGrade), // Assuming itemGrade is a string, convert it to an integer if needed
      categoryId: parseInt(formData.itemCategory), // Assuming itemCategory is a string, convert it to an integer if needed
      title: formData.itemTitle,
      description: formData.itemDescription,
      qty: parseInt(formData.itemQuantity), // Assuming itemQuantity is a string, convert it to an integer if needed
      discount: parseInt(formData.itemDiscount), // Assuming itemDiscount is a string, convert it to an integer if needed
      price: parseFloat(formData.itemPrice), // Assuming itemPrice is a string, convert it to a float if needed
      images: formData.itemImages.map((imageUrl) => ({
        imageUrl,
        isEnabled: true,
      })),
      sizes: formData.itemSize.map((name) => ({
        name,
      })),
    };

    console.log(requestData);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any additional headers if needed
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response data as needed
        console.log("POST Request Successful:", data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });

    onSubmit(formData);
  };

  return (
    <div id="respond" className="comment-respond mb-lg-0">
      <h4 className="title-border">{title}</h4>
      <div className="add-comment">
        <form
          action="#"
          method="post"
          id="commentform"
          className="comment-form"
          onSubmit={handleSubmit}
        >
          <div className="w-100 mb-4">
            <label htmlFor="itemCategory" style={labelStyle}>
              Item Category
            </label>
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              id="itemCategory"
            >
              <option value="All">All Categories</option>
              <option value="1">Shirts</option>
              <option value="2">Trowsers</option>
              <option value="3">Sweaters</option>
              <option value="4">Shoes</option>
              <option value="5">New Category</option>
            </select>
          </div>

          <div className="w-100 mb-4">
            <label htmlFor="itemGrade" style={labelStyle}>
              Item Grade
            </label>
            <select
              value={selectedGrade}
              onChange={handleGradeChange}
              id="itemGrade"
            >
              <option value="All">All Grade</option>

              <option value="2">Reception</option>
              <option value="3">KINDERGARDEN-1</option>
              <option value="4">KINDERGARDEN-2</option>
              <option value="5">YEAR-1</option>
              <option value="6">YEAR-2</option>
              <option value="7">YEAR-3</option>
              <option value="8">YEAR-4</option>
            </select>
          </div>
          <div className="w-100 mb-4">
            <label htmlFor="itemSize" style={labelStyle}>
              Item Size
            </label>
            <select
              value={selectedSizes}
              onChange={handleSizeChange}
              id="itemSize"
              multiple={true} // Allow multiple selections
            >
              <option value="1">Extra Small</option>
              <option value="2">Small</option>
              <option value="3">Medium</option>
              <option value="4">Large</option>
              <option value="5">Extra Large</option>
            </select>
          </div>

          <div className="row">
            <div>
              <label htmlFor="itemTitle" style={labelStyle}>
                Item Title
              </label>
              <input
                type="text"
                id="itemTitle"
                name="itemTitle"
                className="w-100"
                placeholder="Enter Item Title"
                value={formData.itemTitle}
                onChange={handleInputChange}
              />
            </div>

            <div className="row">
              <div className="col md-6">
                <label htmlFor="itemPrice" style={labelStyle}>
                  Item Price
                </label>
                <input
                  type="number"
                  id="itemPrice"
                  name="itemPrice"
                  className="w-100"
                  placeholder="Item Price"
                  value={formData.itemPrice}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col md-6">
                <label htmlFor="itemQuantity" style={labelStyle}>
                  Item Quantity
                </label>
                <input
                  type="number"
                  id="itemQuantity"
                  name="itemQuantity"
                  className="w-100"
                  placeholder="Enter Item Quantity"
                  value={formData.itemQuantity}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <label htmlFor="itemDiscount" style={labelStyle}>
                Item Discount
              </label>
              <input
                type="number"
                id="itemDiscount"
                name="itemDiscount"
                className="w-100"
                placeholder="Item Discount"
                value={formData.itemDiscount}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="itemDescription" style={labelStyle}>
                Item Description
              </label>
              <input
                type="text"
                id="itemDescription"
                name="itemDescription"
                className="w-100"
                placeholder="Item Description"
                value={formData.itemDescription}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <Form.Group controlId="itemImages" className="w-100">
            <Form.Label style={labelStyle}>
              Item Images (Multiple URLs)
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter image URLs separated by commas"
              value={formData.itemImages.join(",")}
              onChange={handleImageChange}
            />
          </Form.Group>

          <button type="submit" className="lab-btn w-100 mt-3">
            <span>{btnText}</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddProductsForm;
