import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";

const Academics = () => {
  return (
    <Fragment>
        <Header />
          <div className="pageheader-section style-2 mb-20" style={{marginBottom:100}}>
            <div className="container">
              <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse"></div>
              <div>
                  <h2>Join us at Hidaya International Schools for a world-class, STEM-equipped education in a modern, inclusive environment.</h2>
                  <p>Our international staff and progressive teaching methods ensure students have access to the latest technology and educational advancements while our focus on traditional Islamic values and inclusivity creates a welcoming community. Discover the future of education today.</p>
                  <h3>Education Levels:</h3>
                  <ul>
                    <li>Early Years</li>
                      <ul>
                        <li>Reception</li>
                        <li>Kindergarten-1</li>
                        <li>Kindergarten-2</li>
                      </ul>
                    <li>Primary Section</li>
                      <ul>
                        <li>Grade 1 & 5</li>
                      </ul>
                    <li>Middle Section</li>
                      <ul>
                        <li>Year 6</li>
                        <li>Year 7</li>
                        <li>Year 8</li>
                      </ul>
                    <li>Higher Secondary Section</li>
                      <ul>
                        <li>Pre O-Level</li>
                        <li>O-Level</li>
                        <li>A-Level</li>
                      </ul>
                  </ul>
                </div>
              </div>
            </div>
        <Footer/>
    </Fragment>
  )
}

export default Academics