import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";


const Coding = () => {
    return (
        <Fragment>
            <Header />
            <div className="pageheader-section style-2" style={{backgroundImage:`url(assets/images/course/Coding-Head.png)`}}>
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                        <div className="col-lg-7"></div>
                        <div className="col-lg-5 col-12">
                            <div className="pageheader-thumb">
                                <img src="assets/images/course/CODING.png" alt="rajibraj91" className="w-100" />
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>CODING</h3>
                                            <p>Coding for young children, aged 6 and above, can be a fantastic way to introduce them to computational thinking and problem-solving skills. Here are some coding options specifically designed for young kids:</p>
                                          
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Scratch Jr: </strong> Scratch Jr is a visual programming language specifically designed for young children. It allows them to create interactive stories, games, and animations by dragging and snapping colorful blocks together. It's a great platform to introduce kids to the basics of coding in a playful and intuitive way.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Blockly:</strong> Blockly is a visual programming editor that uses blocks to teach coding concepts. It offers a simple visual interface where kids can drag and drop blocks to create programs. Blockly can be found in various platforms and coding tools, including Code.org and Tynker.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Kodable:</strong> Kodable is a game-based learning platform that teaches young kids the fundamentals of coding. It offers a series of puzzles and challenges that introduce concepts like sequencing, loops, and conditionals. Kodable is specifically designed for early learners and provides a fun and interactive coding experience.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>Bee-Bot: </strong> Bee-Bot is a physical robot designed for young children to learn programming concepts. Kids can give commands to the robot using buttons on its back, teaching them about sequencing and navigation. Bee-Bot offers a hands-on approach to coding and can help kids understand the connection between code and physical movement.
                                                </li>
                                                <li><i className="icofont-tick-mark"></i>
                                                    <strong>CodeSpark Academy:</strong> CodeSpark Academy is a mobile app that introduces young children to coding through interactive games and puzzles. It uses a drag-and-drop coding interface and offers a wide range of activities to develop coding skills, critical thinking, and creativity.
                                                </li>
                                                
                                            </ul>
                                            <p>When introducing coding to young children, it's important to focus on their engagement and enjoyment. Choose platforms and tools that provide a playful and interactive learning experience, allowing them to explore and experiment with coding concepts. Encourage creativity, problem-solving, and collaboration while making it a fun and enjoyable journey into the world of coding.</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Coding;