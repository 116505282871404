import React,{ Component, Fragment, useState,useEffect,Suspense } from "react";
import { Link } from "react-router-dom";
//import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import baseURL from '../api/index';
import Loader from '../loader/Loader';

const title = "Login";
const socialTitle = "Login With Social Media";
const btnText = "Sign in";

const Footer = React.lazy(() => import('../component/layout/footer'))

const LoginPage = () => {
    const navigate = useNavigate()
    const [username , setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [usererror,setUserError] = useState('')
    const [otpbutton ,setOtpbutton] = useState(false)
    const [userId,setUserId] = useState('')
    const handleUsername = e =>{
        e.preventDefault()
        const value = e.target.value.replace(/\s/g, '')
        setUsername(value)
    }
    
    const handlePassword = e =>{
        e.preventDefault()
        const value = e.target.value
        setPassword(value)
    }
    const handleLogin = async(e) =>{


            e.preventDefault()
            const fillData = {
                username:username,
                password:password
            }
            if(username === '' || password === ''){
                setUserError('Please Enter Username/Password')
            }else{
                // const data = await axios.post(`${baseURL}/api/authentication/login`,fillData)
                // console.log('login data ',data)
                await axios.post(`${baseURL}/api/authentication/login`,fillData)
                .then(res =>{
                    const data = res?.data
                    localStorage.setItem('isUserLogin',JSON.stringify(res))
                    const localStorageData = localStorage.getItem('isUserLogin')
                    navigate('/careers')
                    
                    setUserError('')
                })
                .catch(error =>{
                    if (error.response) {
                        setUserError(error.response.data.message)
                        if(error.response.status === 401){
                            setOtpbutton(true)
                            setUserId(error.response.data?.data)
                        }
                    } else if (error.request) {
                       
                    } else {
                        
                    }
                })
                
            }
       
    }

    const [bgcolor ,setBGColor] = useState('red')
    const [color , setColor] = useState('white')
    const [isset ,setIsset] = useState(true)
    
    useEffect(()=>{
        const ColorChange = () =>{
            const timer = setTimeout(() => {
                if(isset === true){
                    setBGColor('yellow')
                    setColor('red')
                    setIsset(false) 
                }else{
                    setBGColor('red')
                    setColor('white')
                    setIsset(true)  
                }
                
            }, 500);
            return () => clearTimeout(timer);
        }
        ColorChange()
        fetchData()
    },[isset,otpbutton])

    const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [showModal, setShowModal] = useState(false);

    const fetchData = async () => {
		try {
		  // Simulating an API call
		  const response = await new Promise((resolve) =>
			setTimeout(() => resolve({ data: 'Some data' }), 2000)
		  );
		  setData(response.data);
		  setLoading(false); // Data is fetched, stop loading
		} catch (error) {
		  console.error('Error fetching data:', error);
		  setLoading(false); // Stop loading even if there's an error
		}
	  };
	
	  if (loading) {
		return <Loader />; // Show loader while loading
	  }

    return (
        <Fragment>
            <Header />
                <div className="pageheader-section style-2 mb-20">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center ">         
                            <div className="col-md-6">
                                <h5 style={{color:'red',paddingLeft:4}}>Step-by-Step Guide to Job Application:</h5>
                                <ol>
                                    <li>
                                        <strong>Visit Our Website:</strong> Go to our school's website https://hint.edu.pk/login    
                                    </li>
                                    <li>
                                        <strong>Navigate to Sign-Up:</strong> Look for the "Sign Up" or "Create Account" button on the homepage and click on it.
                                    </li>
                                    <li>
                                        <strong>Fill in Your Details:</strong> You will be directed to the sign-up form. Provide accurate information, including your name, email address, password, and other required details.
                                    </li>
                                    <li>
                                        <strong>Submit the Form:</strong> Once you've filled in the necessary information, click the "Submit" or "Sign Up" button at the bottom of the form. 
                                    </li>
                                    <li>
                                        <strong>Check Your Email:</strong> After submitting the form, a confirmation email will be sent to the email address you provided during sign-up.
                                    </li>
                                    <li>
                                        <strong>Open Your Inbox:</strong> Access your email inbox and locate the email from us. Check your spam or junk folder if you don't see the email in your primary inbox.
                                    </li>
                                    <li>
                                        <strong>Click the Verification Link:</strong> Open the email and click on the verification link provided. This will confirm your email address and activate your account.
                                    </li>
                                    <li>
                                        <strong>Return to Our Website:</strong> Once you've verified your email address, return to our website.
                                    </li>
                                    <li>
                                        <strong>Sign In:</strong> Click on the "Sign In" button and enter the email address and password you used during sign-up.
                                    </li>
                                    <li>
                                        <strong>Welcome Back:</strong> You're now successfully signed into your account. Please explore our website and stay updated with all the latest news, events, and resources.
                                    </li>

                                </ol>
                                
                            </div>
                            <div className="col-md-6">
                                <div className="login-section mb-20">
                                    <div className="account-wrapper">
                                            <h3 className="title">{title}</h3>
                                            <form className="account-form">
                                                {usererror ? 
                                                    <p className="text-danger">{usererror} {otpbutton?  <Link className="btn btn-link" to={'/otp/'+userId}> Click for Verification</Link> : null} </p>
                                                :null}
                                                <div className="form-group">
                                                    <input
                                                        value={username}
                                                        type="text"
                                                        name="name"
                                                        placeholder="User Name"
                                                        onChange={handleUsername}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        value={password}
                                                        placeholder="Password"
                                                        onChange={handlePassword}
                                                    />
                                                </div>
                                                
                                                <div className="form-group text-center">
                                                    <button onClick={handleLogin} className="d-block lab-btn"><span>{btnText}</span></button>
                                                </div>
                                            </form>
                                            <div className="account-bottom">
                                                <span className="d-block cate pt-10">Don’t Have any Account?  <Link className="btn" style={{fontWeight:600,paddingLeft:10,paddingRight:10,backgroundColor: bgcolor , color: color}} to="/signup">Sign Up</Link></span>
                                            
                                            </div>
                                        </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            <Suspense>
                <Footer />
            </Suspense>
            
        </Fragment>
    );
}
 
export default LoginPage;