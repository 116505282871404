import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { useCart } from "../context/cart-context";
import { useState, createContext, useContext, useEffect } from "react";
import CartContext from "../context/cart-context";
import del from "../assets/images/shop/del.png";

const stdID = "1234";

const CartPage = () => {
  const {
    cartItems,
    addToCart,
    removeItem,
    setCartTotal,
    setCartSubTotal,
    incrementQuantity,
    decrementQuantity,
  } = useContext(CartContext);
  const [subTotal, setSubTotal] = useState(0);
  const [studentID, setStudentID] = useState();
  const [showProceedToCheckout, setShowProceedToCheckout] = useState(false);
  const [invalidStudentID, setInvalidStudentID] = useState(false);

  console.log("from cart page:", cartItems);

  const [firstColor ,setFirstColor] = useState('yellow')
  const [secondColor ,setSecondColor] = useState('#0B3155')
  const [thirdColor,setThirdColor] = useState('#0B3155')
  const [isset,setIsset] = useState(1)

  useEffect(() => {
    const total = cartItems.reduce((accumulator, item) => {
      const itemPrice = parseFloat(item.price);
      return accumulator + itemPrice * item.quantity;
    }, 0); // Start with an initial value of 0

    setSubTotal(total);
    setCartSubTotal(total);

    const ColorChange = () =>{
      const timer = setTimeout(() => {
          if(isset === 1){
              setFirstColor('yellow')
              setSecondColor('#0B3155')
              setThirdColor('#0B3155')
              setIsset(2) 
          }else if(isset === 2){
              setFirstColor('#0B3155')
              setSecondColor('yellow')
              setThirdColor('#0B3155')
              setIsset(3)  
          }else if(isset === 3){
            setFirstColor('#0B3155')
            setSecondColor('#0B3155')
            setThirdColor('yellow')
            setIsset(1)  
          }
          
      }, 400);
      return () => clearTimeout(timer);
  }
  ColorChange()


  }, [cartItems,isset,firstColor]);


  

  const handleRemoveItem = (index) => {
    removeItem(index);
  };

  const increase = (index) => {
    incrementQuantity(index);
  };

  const decrease = (index) => {
    decrementQuantity(index);
  };

  const handleStudentIDSubmit = () => {
    if (studentID === stdID) {
      setShowProceedToCheckout(true);
      // Clear the invalid student ID message if it was previously shown
      setInvalidStudentID(false);
    } else {
      setShowProceedToCheckout(false);
      // Set the invalid student ID message to true
      setInvalidStudentID(true);
    }
  };

  return (
    <Fragment>
      <Header />
      <div className="pageheader-section style-2 mb-10">
        <div className="shop-cart padding-tb">
          <div className="container">
            <div className="section-wrapper">
              <div className="cart-top">
                <table>
                  <thead>
                    <tr>
                      <th className="cat-product">Product</th>
                      <th className="cat-price">Price</th>
                      <th className="cat-quantity">Quantity</th>
                      <th className="cat-toprice">Total</th>
                      <th className="cat-edit">Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((val, i) => (
                      <tr key={i}>
                        <td className="product-item cat-product">
                          <div className="p-thumb">
                            <Link to={`/shop-single/${val.product.data.id}`}>
                              <img
                                src={`http://api.hintschool.org/${val.product.data.images[0].imageUrl}`}
                              />
                            </Link>
                          </div>
                          <div className="p-content">
                            <Link to={`/shop-single/${val.product.data.id}`}>
                              {val.product.data.title}
                            </Link>
                          </div>
                        </td>
                        <td className="cat-price">{val.price} PKR</td>
                        <td className="cat-quantity">
                          <div className="cart-plus-minus">
                            <a
                              className="dec qtybutton"
                              onClick={() => decrease(i)}
                            >
                              -
                            </a>

                            <input
                              className="cart-plus-minus-box"
                              type="text"
                              name="qtybutton"
                              value={val.quantity}
                            />
                            <a
                              className="inc qtybutton"
                              onClick={() => increase(i)}
                            >
                              +
                            </a>
                          </div>
                        </td>
                        <td className="cat-toprice">
                          {parseFloat(val.price) * val.quantity + ".00" + " PKR"}
                        </td>
                        <td className="cat-edit">
                          <a
                            onClick={() => handleRemoveItem(i)}
                            style={{ cursor: "pointer" }}
                          >
                            {/* Pass the index to handleRemoveItem */}
                            <img src={del} alt="Delete Image" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="cart-bottom">
                

                <div className="shiping-box">
                  <div className="row">
                
                    <div className="col-md-6 col-12">
                      <div className="cart-overview">
                        <h3>Cart Totals</h3>
                        <ul className="lab-ul">
                          <li>
                            <span className="pull-left">Cart Subtotal</span>
                            <p className="pull-right">
                              {subTotal.toFixed(2) + ' PKR'}
                              {/* Use toFixed to format decimal */}
                            </p>
                          </li>
                          {/* <li>
                            <span className="pull-left">
                              Shipping and Handling
                            </span>
                            <p className="pull-right">Free Shipping</p>
                          </li>
                          <li>
                            <span className="pull-left">Order Total</span>
                            <p className="pull-right">
                              {"$" + subTotal.toFixed(2)}
                            </p>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cart-checkout-box">
                  <form
                    className="cart-checkout"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <Link to={"/check-out-page"}>
                        <button
                          type="submit"
                          style={{
                            fontSize: "20px",
                            backgroundColor: "#0B3155",
                            
                            fontWeight:100
                          }}
                        >
                          <span style={{color: "white"}}>
                            Proceed to Checkout
                          </span>
                          
                          <i style={{color:firstColor}} class="icofont-thin-double-right" ></i>
                          <i style={{color:secondColor}} class="icofont-thin-double-right"></i>
                          <i style={{color:thirdColor}} class="icofont-thin-double-right"></i>
                       
                        </button>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default CartPage;
