import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { useState, useEffect } from "react";

const title = "Shipping Address";
const btnText = "Calculate Shipping";

const PaymentDetailsForm = ({
  onSubmit,
  address,
  firstName,
  familyName,
  email,
  contactNumber,
}) => {
  const labelStyle = {
    fontWeight: "bold", // Make labels bold
    marginBottom: "5px", // Add margin-bottom to labels
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    phone: "",
    email: "",
    couponCode: "",
    additionalInformation: "",
  });

  useEffect(() => {
    setFormData({
      firstName: firstName || "",
      lastName: familyName || "",
      address: address || "",
      phone: contactNumber || "",
      email: email || "",
      couponCode: "",
      additionalInformation: "",
    });
  }, [address, firstName, familyName, email, contactNumber]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Call the onSubmit callback with the form data
    onSubmit(formData);
  };

  // Watch for changes in formData and trigger onSubmit
  useEffect(() => {
    onSubmit(formData);
  }, [formData, onSubmit]);

  useEffect(() => {
    console.log("Address from payment:", address);
    console.log("First Name from payment:", firstName);
    console.log("Family Name from payment:", familyName);
    console.log("Email from payment:", email);
    console.log("Contact Number from payment:", contactNumber);
  }, [address, firstName, familyName, email, contactNumber]);

  return (
    <div id="respond" className="comment-respond mb-lg-0">
      <h4 className="title-border">{title}</h4>
      <div className="add-comment">
        <form
          action="#"
          method="post"
          id="commentform"
          className="comment-form"
          onSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="firstName" style={labelStyle}>
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="w-100"
                placeholder="Enter First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" style={labelStyle}>
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="w-100"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                disabled={true}
              />
            </div>
          </div>
          <div>
            <label htmlFor="address" style={labelStyle}>
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              className="w-100"
              placeholder="Address"
              value={formData.address}
              onChange={handleInputChange}
            />
            <label htmlFor="phone" style={labelStyle}>
              Phone
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              className="w-100"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleInputChange}
              disabled={true}
            />

            <label htmlFor="email" style={labelStyle}>
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="w-100"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              disabled={true}
            />

            <label htmlFor="email" style={labelStyle}>
              Coupon Code
            </label>
            <input
              type="text"
              id="couponCode"
              name="couponCode"
              className="w-100"
              placeholder="Coupon Code"
              value={formData.couponCode}
              onChange={handleInputChange}
            />

            <label htmlFor="additionalInformation" style={labelStyle}>
              Additional Information
            </label>
            <input
              type="text"
              id="additionalInformation"
              name="additionalInformation"
              className="w-100"
              placeholder="Additional Information"
              value={formData.additionalInformation}
              onChange={handleInputChange}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentDetailsForm;
