import React,{Fragment} from 'react'

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import axios from 'axios';
import { Link } from 'react-router-dom';
import ClubAndSociety from '../component/club_society';

const ClubSocities = () =>{
    return(
        <Fragment>
            <Header />
                <div className="pageheader-section style-2 mb-20">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                            <div className='col-sm-12 mb-10'>
                                <ClubAndSociety />
                            </div>
                            <div className='col-sm-12 mb-10'>
                                <h2>CLUBS AND SOCIETIES</h2> 
                                <p>Clubs and societies play a significant role in the overall educational experience of students at Hidaya International Schools. Here are some reasons why they are important:</p>
                                <ol>
                                    <li>Socialization: Clubs and societies provide a platform for students to interact with others who share similar interests and hobbies. This helps in building friendships and creating a sense of community.</li>
                                    <li>Skill Development: Clubs and societies offer opportunities for students to develop new skills and interests. For example, a debate club can help students develop their public speaking and critical thinking skills.</li>
                                    <li>
                                        Leadership: Clubs and societies provide opportunities for students to take on leadership roles, organize events, and make decisions. This helps students develop important leadership skills that will be useful in their future careers.
                                    </li>
                                    <li>
                                        Personal Growth: Participating in clubs and societies allows students to explore their interests and learn more about themselves. It also helps students to remain confident in their learning and life.
                                    </li>
                                </ol>
                                <p>
                                    Additionally, they provide a break from the academic rigor of traditional classes and allow students to pursue their passions and interests in a supportive and encouraging environment. This can help to foster a love of learning and a lifelong appreciation for the subjects they explore.
                                </p>
                                <p>
                                    They also provide opportunities for students to give back to their communities and make a positive impact. For example, a volunteer club may organize events to help those in need or raise awareness about important causes.
                                </p>
                                <p>
                                In conclusion, clubs and societies play a crucial role in the development of students by providing opportunities for socialization, skill development, leadership, personal growth, career preparation, and community service. They help to create a well-rounded educational experience and prepare students for the future.
                                </p>
                                
                                <div style={{textAlign:'right',marginRight:60}}>
                                    
                                    <p><strong>ALI RAZA</strong></p>
                                    <p>CEO (Hidaya International Schools)</p>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <table className='table mb-40 table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Grade</th>
                                            <th>Club and Societies Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Grade I & III</th>
                                            <td>
                                                <Link to={'/clubs-and-socities/1'}>Art and Craft Club</Link> , 
                                                <Link to={'/clubs-and-socities/2'}>Scrabble Club</Link> ,
                                                <Link to={'/clubs-and-socities/3'}>Abacus Club</Link> ,
                                                <Link to={'/clubs-and-socities/4'}>Chess Club</Link> ,
                                                <Link to={'/clubs-and-socities/5'}>Story Club</Link> ,
                                                <Link to={'/clubs-and-socities/6'}>Lago and Robotics Club</Link> ,
                                                <Link to={'/clubs-and-socities/7'}>Calligraphy Club</Link> ,
                                                <Link to={'/clubs-and-socities/8'}>Personality Development Club</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Grade IV & V
                                            </th>
                                            <td>
                                                <Link to={'/clubs-and-socities/1'}>Art and Craft Club</Link> ,
                                                <Link to={'/clubs-and-socities/9'}>General Awareness and International Understanding Club</Link> ,
                                                <Link to={'/clubs-and-socities/2'}>Scrabble Club</Link> ,
                                                <Link to='/clubs-and-socities/10'>Sudoku Club</Link> ,
                                                <Link to={'/clubs-and-socities/11'}>Taekwondo Club</Link> ,
                                                <Link to={'/clubs-and-socities/12'}>Astronomy Club</Link> ,
                                                <Link to={'/clubs-and-socities/13'}>Magic Club</Link> ,
                                                <Link to={'/clubs-and-socities/3'}>Abacus Club</Link> ,
                                                <Link to={'/clubs-and-socities/4'}>Chess Club</Link> ,
                                                <Link to={'/clubs-and-socities/6'}>Lago and Robotics Club</Link> ,
                                                <Link to={'/clubs-and-socities/7'}>Calligraphy Club</Link> ,
                                                <Link to={'/clubs-and-socities/8'}>Personality Development Club</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Grade VI & VII</th>
                                            <td>
                                            <Link to={'/clubs-and-socities/12'}>Astronomy Club</Link> ,
                                            <Link to={'/clubs-and-socities/19'}>Digital Club</Link> ,
                                            <Link to={'/clubs-and-socities/7'}>Calligraphy Club</Link> ,
                                            <Link to={'/clubs-and-socities/4'}>Chess Club</Link> ,
                                            <Link to={'/clubs-and-socities/6'}>Lago and Robotics Club</Link> ,
                                            <Link to={'/clubs-and-socities/14'}>Photography Club</Link> ,
                                            <Link to={'/clubs-and-socities/8'}>Personality Development Club</Link> ,
                                            <Link to={'/clubs-and-socities/15'}>Housekeeping Club</Link> ,
                                            <Link to={'/clubs-and-socities/16'}>Environmental Club</Link> ,
                                            <Link to={'/clubs-and-socities/11'}>Taekwondo Club</Link> ,
                                            <Link to={'/clubs-and-socities/17'}>Literary Club</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Class VIII, IX and X (O-Level)</th>
                                            <td>
                                                <Link to={'/clubs-and-socities/12'}>Astronomy Club</Link> ,
                                                <Link to={'/clubs-and-socities/7'}>Calligraphy Club</Link> ,
                                                <Link to={'/clubs-and-socities/4'}>Chess Club</Link> ,
                                                <Link to={'/clubs-and-socities/18'}>MUN (Model United Nations)</Link> ,
                                                <Link to={'/clubs-and-socities/19'}>Digital Club</Link> ,
                                                <Link to={'/clubs-and-socities/6'}>Lago and Robotics Club</Link> ,
                                                <Link to={'/clubs-and-socities/14'}>Photography Club</Link> ,
                                                <Link to={'/clubs-and-socities/8'}>Personality Development Club</Link> ,
                                                <Link to={'/clubs-and-socities/20'}>Legal Literacy and Human Rights Club </Link> ,
                                                <Link to={'/clubs-and-socities/17'}>Literary Club</Link> ,
                                                <Link to={'/clubs-and-socities/21'}> Interact and Social Responsibility Club</Link> ,
                                                <Link to={'/clubs-and-socities/22'}> Quran Club (mandatory for all students) </Link> ,
                                                <Link to={'/clubs-and-socities/23'}> Environmental and sustainability Club</Link> ,
                                                <Link to={'/clubs-and-socities/24'}> Gaming and Technology Club </Link> ,
                                                <Link to={'/clubs-and-socities/25'}> Entrepreneurship and Business Club</Link> ,
                                                <Link to={'/clubs-and-socities/26'}> Health and Wellness Club</Link>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
        </Fragment>
    )
}

export default ClubSocities