
import { Link } from "react-router-dom";

const newsTitle = "Want Us To Email You About Special Offers And Updates?";
const siteTitle = "Site Map";
const useTitle = "Useful Links";
const socialTitle = "Social Contact";
const supportTitle = "Download the Mobile App";


const siteList = [
    {
        text: 'Documentation',
        link: '#',
    },
    {
        text: 'Contact',
        link: '/contact-us',
    },
]

const useList = [
    {
        text: 'About Us',
        link: '/about-us',
    },
    {
        text: 'Code of Conducts',
        link: '/code-of-conducts',
    },
    {
        text: 'Clubs & Societies',
        link: '/club-socities',
    },
    {
        text: 'Careers',
        link : '/careers'
    }
]

const socialList = [
    {
        text: 'Facebook',
        link: 'https://www.facebook.com/hintschoolofficial',
    },
    {
        text: 'Instagram',
        link: 'https://www.instagram.com/hintschoolsofficial/',
    },
    {
        text: 'LinkedIn',
        link: 'https://www.linkedin.com/company/hidaya-international-school/',
    },
    {
        text: 'Whatsapp',
        link: 'https://wa.me/message/R7E6Z6AMNZLSG1',
    },
]

const supportList = [
    {
        text: 'Help Center',
        link: '#',
    },
    {
        text: 'Changelog',
        link: '#',
    },
]



const Footer = () => {
    return (
        <div className="news-footer-wrap">
            <div className="fs-shape">
                <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" />
                <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" />
            </div>
            
            <div className="news-letter">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="news-title">
                            <h3>{newsTitle}</h3>
                        </div>
                        <div className="news-form">
                            <form action="/">
                                <div className="nf-list">
                                    <input type="email" name="email" placeholder="Enter Your Email" />
                                    <input type="submit" name="submit" value="Subscribe Now" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <footer>
                <div className="footer-top padding-tb pt-0">
                    <div className="container">
                        <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{siteTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {siteList.map((val, i) => (
                                                        <li key={i}><Link to={val.link}>{val.text}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{useTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {useList.map((val, i) => (
                                                        <li key={i}><Link to={val.link}>{val.text}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{socialTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {socialList.map((val, i) => (
                                                        <li key={i}><a href={val.link}>{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{supportTitle}</h4>
                                            </div>
                                            <div className="content">
                                               
                                                <a href="https://play.google.com/store/apps/details?id=com.schools.hidaya" target="_blank">
                                                    <img src="/assets/images/app/playstore.png" alt="playstore logo" style={{width:'210px'}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom style-2">
                    <div className="container">
                        <div className="section-wrapper">
                            <p>&copy; 2023 <Link to="/" style={{color:'#1f1f33'}}>Hidaya International Schools </Link> Powered By <a href="https://hinttch.io/" target="_blank"><b style={{color:'#C08E2D'}}>HINT Tech Solutions</b></a> </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
 
export default Footer;